<template>
  <div style="z-index:1000">
    <div id="header-wrapper" class="header-wrapper">
      <header
        id="header"
        data-cy="cy-navbar navbar-v2"
        class="navbar navbar-v2  once-more"
style="display:flex; justify-content:space-between;"        
      >
      <div class="header-part1" style="display:flex; align-items:center; gap:6px">
        <button
          data-cy="cy-navbar__menu"
          class="navbar__menu"
          @click="toggleSidebar"
          ref="navbar__menu_button"
        >
          <span>
            
          </span>
        </button>
        <router-link to="/" data-cy="cy-navbar__logo" class="navbar__logo">
          <!-- <picture data-cy="cy-navbar__logo-img" class="navbar__logo-img">
            <source
              media="(max-width: 478px)"
              :srcset="
                logoFeviconData?.site_logo
              "
              width="40"
              height="31"
            />
            <source
              media="(min-width: 479px)"
              :srcset="
               logoFeviconData?.site_logo
              "
              width="121"
              height="22"
            />
            <img
             v-if="!isAuthicate"
              :src="logoFeviconData?.site_logo"
              alt="BitStarz logo"
              width="121"
              height="22"
            style="min-width:150px"

            />
          </picture> -->
          <div class="show-image-desktop">
            <img  v-if="logoFeviconData?.site_logo"
              class="des-logo"
              :src="
                logoFeviconData?.site_logo
              "
              
            
            />
          </div>
          <div class="show-image-mobile">
            <img  v-if="logoFeviconData?.site_logo_mobile"
              class="des-logo"
              :src="
                logoFeviconData?.site_logo_mobile
              "
              
            
            />
          </div>
        </router-link>
        <section
          data-cy="cy-navbar__search"
          class="c-games-search navbar__search is-trending-games"
        >
        
        <div  class="c-search-container" ref="searchContainer">
         
          <div
          @click="showDropdown = true"

          style=' width:50px;height:40px;margin-left:8px'
            class="c-button search--icon-mobile has-icon color-dark-purple size-m"
          >
          <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            fill="white"
            d="M22.49 21.07l-5.63-5.63A9.94 9.94 0 0 0 20 10c0-5.52-4.48-10-10-10S0 4.48 0 10s4.48 10 10 10c2.3 0 4.41-.77 6.13-2.07l5.63 5.63c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41zM10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
        </svg>
          </div>
         <div 
          @click="showDropdown=true"
        :class="['c-search-content', { 'c-search-content-active': showDropdown }]"
        >
         <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        width="24"
        height="24"
         >
        <path
          fill="white"
          d="M22.49 21.07l-5.63-5.63A9.94 9.94 0 0 0 20 10c0-5.52-4.48-10-10-10S0 4.48 0 10s4.48 10 10 10c2.3 0 4.41-.77 6.13-2.07l5.63 5.63c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41zM10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        />
      </svg>
     
      <input
        id="gameSearchInput"
        placeholder="Find game or game studio (try roulette, book...)"
        v-model="searchQuery"
        autocomplete="off"
        :name="randomizedName2"
        :class="['c-search-input', { 'c-search-input-active': showDropdown }]"
        
        @input="showDropdown = true"
      />
     <span v-if="showDropdown" @click="showDropdown = false" class="search-cross">x</span>
    </div>
    
    <div v-if="showDropdown" class="c-dropdown">
      <div class='drop-c'></div>
     
  <Search :searchQuery="searchQuery" />
</div>

        </div>
        </section>
      </div>
        
      <div style="display:flex; gap:6px;justify-content:flex-end">
  
  <div   class="hide-desktop" >

      <div 
        v-if="!isAuthicate"
  style="display: flex;justify-content:space-between;align-items: center;gap:10px;min-width:90px;  background-color: #282544; height:40px; border-radius: 6px; padding-left:10px">
    <span style="color: white;text-wrap:nowrap; font-size: 13px; padding-right: 5px;">{{userData?.data?.amount&&(userData?.data?.amount).toFixed(2)}} {{userData?.data?.currency}}</span>

    <p
    @click="openModal1"
    
    style="display:flex;border-top-right-radius: 6px; border-bottom-right-radius: 6px; align-items:center; justify-content:center;background-color: #01BD71; border: none; width: 45px; height: 100%; color: white; font-size: 30px; font-weight:800; cursor: pointer;">
      +
    </p>
  </div>
</div>  

       <div style="width:100%;display:flex; justify-content:space-between;gap:8px;">
        <button
          v-if="isAuthicate"
          data-cy="cy-login-btn-header"
          class="c-button login-btn color-dark-purple size-m loginSignup"
          style="text-wrap:nowrap;"
          @click="loginFunction(true)"
        >
          <span>{{t(`Log In`)}}</span>
        </button>
        
        <button
          v-if="isAuthicate && logoFeviconData.is_signup_enabled"
          data-cy="signup_btn_header"
          class="bs-c-button-yellow navbar__signup loginSignup"
          @click="signUpFunction(true)"
          style="text-wrap:nowrap;"

        >
          <strong>{{t(`Sign Up`)}}</strong>
        </button>
      </div>
        <div
          v-if="!isAuthicate"
          style="display: flex; align-items: center; gap: 5px"
        >
          <!-- Bell Icon with Tooltip -->
          <div
            class="icon-container"
            style="
              background-color: #3b3955;
              height: 35px;
              width: 45px;
              border-radius: 6px;
            "
            @mouseover="showTooltip('bell')"
            @mouseleave="hideTooltip('bell')"
          >
            <i
              class="fas fa-bell white-icon"
              style="color: #dfe4e8; font-size: 20px"
            ></i>
            <div v-if="tooltips.bell" class="tooltip">{{t(`Notifications`)}}</div>
          </div>

          <!-- Admin Icon with Tooltip -->
          <div
            style="
              background-color: #3b3955;
              height: 35px;
              width: 45px;
              border-radius: 6px;
            "
            class="icon-container"
            @mouseover="showTooltip('admin')"
            @mouseleave="hideTooltip('admin')"
          >
            <i class="fas fa-user" style="color: #dfe4e8; font-size: 20px"></i>
            <div v-if="tooltips.admin" class="tooltip tooltipS">

              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;
                  padding: 10px;
                  width:100%;
                "
              >
              <div style="width:100%; display:flex; justify-content:space-between; align-items:center;gap:20px">

                <i
                  class="fas fa-user"
                  style="color: #dfe4e8; font-size: 20px"
                ></i>
                <div style="display: flex; flex-direction: column;justify-content:start;align-content:start;width:100%; gap:0px">
                  <p style="font-weight: bold; text-align: left">
                   {{userData?.data?.username}}
                  </p>
                  <p style="text-align: left; margin-top: -10px">
            {{
        (userData && userData.data && userData.data.amount ? (userData.data.amount).toFixed(2) : 0)}}
            <span style="font-size: 12px">{{ userData?.data?.currency }}</span>
          </p>

                </div>
              </div>

  <p style="display:flex; align-items:center;gap:2px;width:100%;text-align:center" > <span style="font-weight:bold">Bonus</span> : {{(userData.data?.bonus ? parseFloat(userData.data.bonus) : 0).toFixed(2)}}  <span style="font-weight:bold">{{userData?.data?.currency}}</span> </p>
              </div>

              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                  gap: 10px;
                  padding: 10px;
                "
              >
                <p  @click="$router.push('/promotions')"  class="route-s" style="font-weight: bold; text-align: left">
                  {{t(`Promotions`)}}
                </p>
                <p @click="profileFunction(true, 'history')" class="route-s" style="font-weight: bold; text-align: left">
                  {{t(`Transactions`)}}
                </p>
                <p @click="profileFunction(true, 'history')" class="route-s" style="font-weight: bold; text-align: left">
                  {{t(`Bets`)}}
                </p>
                <p @click="profileFunction(true, 'myprofile')" class="route-s" style="font-weight: bold; text-align: left">
                  {{t(`My Profile`)}}
                </p>
              </div>

              <div
                style="
                  height: 0.5px;
                  width: 100%;
                  margin-top: 0px;
                  background-color: #dfe4e8;
                "
              ></div>
              <div
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 15px;
                  width: 100%;
                "
              >
                <div
                  @click="logout()"
                  style="
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 18px;
                    width: 100%;
                  "
                  class="logout-css"
                >
                  <i class="fas fa-sign-out-alt"></i>
                  <span class="tooltip-text">{{t(`LOG OUT`)}}</span>
                </div>
              </div>
            </div>
          </div>
<div class="amount-hide">
          <div
          style="background: #3B3955;min-height:36px;display:flex; align-items:center;text-wrap:nowrap"
            data-cy="signup_btn_header"
            class="bs-c-button-green  "
          >
          {{(userData?.data?.amount&&(userData?.data?.amount).toFixed(2))}}
          {{userData?.data?.currency}} 
        </div>
</div>

          <button
            style="background: #01bd71"
            data-cy="signup_btn_header"
            class="bs-c-button-green amount-hide navbar__signup"
            @click="openModal1"
          >
            <strong>{{t(`Deposit`)}}</strong>
          </button>
       
       
        

          
        </div>
      </div>

      </header>

      <div class="bs-c-site-sidebar" :style="{ width: sidebarWidth }">
        <div
          data-cy="cy-mobile-menu-header"
          class="mobile-menu-header"
          :style="{ display: displayTopHeaderMobile }"
        >
          <img
            :src="logoFeviconData?.site_logo"
            data-cy="cy-mob-sidenav-header__logo"
            alt="Bitstarz logo"
            class="mob-sidenav-header__logo"
            style="max-width:200px"
          />
          <button
            class="c-button mobile-menu-header__button is-close has-icon color-dark-purple size-m"
            data-cy="cy-mobile-menu-header__button-close"
            @click="toggleSidebar"
          >
            <font-awesome-icon
              :icon="['fas', 'xmark']"
              style="color: #ffffff"
              class="c-button__icon icon sprite-icons"
            />
          </button>
        </div>
        <div
          class="bs-c-site-sidebar__wrap is-tesla-widget-visible"
          :style="{ padding: padding }"
        >
        <div class="mobileSidebar">
            <div
                      data-cy="cy-piggy-bank-progress__bar-wrapper"
                      class="c-piggy-bank-progress__bar-wrapper"
                    >
                      <div
                        data-cy="cy-piggy-bank-progress__bar"
                        class="c-piggy-bank-progress__bar"
                      >
                        <div
                          data-cy="cy-piggy-bank-progress__bar-icon"
                          class="c-piggy-bank-progress__bar-icon"
                        >
                          <img
                            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/intact.a4fe977.png"
                            alt="piggy"
                            data-cy="cy-piggy-bank-progress__bar-img"
                            class="c-piggy-bank-progress__bar-img"
                          />
                        </div>
                        <div
                          data-cy="cy-piggy-bank-progress__bar-progress"
                          class="c-piggy-bank-progress__bar-progress"
                          style="--progress: 0"
                        ></div>
                        <strong
                          data-cy="cy-piggy-bank-progress__bar-text"
                          class="c-piggy-bank-progress__bar-text"
                        >
                          {{userData?.data?.bonus}}
                          <span style="font-size: 9px">{{ userData?.data?.currency }}</span>

                        </strong>
                      </div>
                    </div>
                    <div class="currencies-dropdown is-sidebar">
                      <div
                        data-cy="cy-header-currency"
                        class="currencies-dropdown__current"
                      >
                      {{ userData && userData.data && userData.data.amount ? (userData.data.amount.toFixed(2)) : 0 }}
        <span style="font-size: 12px">{{ userData?.data?.currency }}</span>
                      </div>
                    </div>
                    <div class="bs-c-account-info__buttons">
                      <button
                        @click="openModal2"
                        class="c-button is-full-width color-light-purple size-m"
                      >
                        <span>{{t(`Cash out`)}}</span>
                      </button>
                      <button
                        @click="openModal1"
                        class="c-button is-full-width size-m"
                      >
                        <span>{{t(`Deposit`)}}</span>
                      </button>
                    </div>
        </div>
          <ul class="bs-c-site-sidebar__list">

            <li class="bs-c-site-sidebar__list-item">
              <router-link
                to="/"
                class="bs-c-site-sidebar__list-link is-active"
              >
                <font-awesome-icon
                  class="bs-c-site-sidebar__list-icon icon sprite-icons"
                  :icon="['fas', 'house-chimney']"
                  style="color: #ffffff"
                />
                <span class="bs-c-site-sidebar__list-text">{{t(`Home`)}}</span>
              </router-link>
            </li>

            <li class="bs-c-site-sidebar__list-item">
              <a
                class="bs-c-site-sidebar__list-link"
                :class="{ 'is-opened': openedIndex === 1 }"
                @click="gamesDropdown(1)"
              >
                <font-awesome-icon
                  class="bs-c-site-sidebar__list-icon icon sprite-icons"
                  :icon="['fas', 'gamepad']"
                  style="color: #ffffff"
                />
                <span class="bs-c-site-sidebar__list-text"> {{t(`Games`)}} </span>
                <span class="bs-c-site-sidebar__arrow">
                  <font-awesome-icon
                    :icon="['fas', 'angle-down']"
                    size="xl"
                    class="icon sprite-icons"
                    style="color: #ffffff"
                  />
                </span>
              </a>
              <ul class="bs-c-site-sidebar__sublist"  >
                <li  class="bs-c-site-sidebar__sublist-item" v-for="category in gameCategory"  :key="category._id" >
                     <!-- Category Icon -->
                  <router-link
                  @click="toggleSidebar"
                    :to="`/ex-game-category/${encodeData(category.name)}/name`"
                    style="display:flex; align-items:center;justify-content: flex-start;width:180px;gap:10px"
                    class="bs-c-site-sidebar__sublist-link"

                  >
                  <img
                  :src="category.icon"
                  alt="Category Icon"
                  style="width: 20px; height: 20px;"
                />
                    <span class="bs-c-site-sidebar__list-text"> {{t(category.name)}} </span>
                  </router-link>
                </li>
              
              </ul>
            </li>

            <li class="bs-c-site-sidebar__list-item">
              <a
                class="bs-c-site-sidebar__list-link"
                :class="{ 'is-opened': openedIndex === 2 }"
                @click="gamesDropdown(2)"
              >
                <font-awesome-icon
                  class="bs-c-site-sidebar__list-icon icon sprite-icons"
                  :icon="['fas', 'gift']"
                  style="color: #ffffff"
                />
                <span class="bs-c-site-sidebar__list-text"> {{t(`Promotions`)}} </span>
                <span class="bs-c-site-sidebar__arrow">
                  <font-awesome-icon
                    :icon="['fas', 'angle-down']"
                    class="icon sprite-icons"
                    style="color: #ffffff"
                  />
                </span>
              </a>
              <ul class="bs-c-site-sidebar__sublist">
                <a
                  href="/promotions"
                  style="text-decoration: none"
                  class="bs-c-site-sidebar__sublist-item"
                  ><span class="bs-c-site-sidebar__sublist-link"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-sidebar is-golden"
                    >
                      <use
                        href="/_nuxt/c4a808059f24cba004b538d7e2a1188f.svg#i-piggy-bank"
                        xlink:href="/_nuxt/c4a808059f24cba004b538d7e2a1188f.svg#i-piggy-bank"
                      ></use>
                    </svg>
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Our`)}} {{t(`Promotions`)}}
                    </span>
                  </span></a
                >
                <!-- <li class="bs-c-site-sidebar__sublist-item"><span class="bs-c-site-sidebar__sublist-link"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-sidebar is-golden">
                                        <use href="/_nuxt/c4a808059f24cba004b538d7e2a1188f.svg#i-icon-tesla"
                                            xlink:href="/_nuxt/c4a808059f24cba004b538d7e2a1188f.svg#i-icon-tesla"></use>
                                    </svg> <span class="bs-c-site-sidebar__list-text">
                                        Tesla Giveaway
                                    </span> </span></li>
                            <li class="bs-c-site-sidebar__sublist-item"><span class="bs-c-site-sidebar__sublist-link"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-sidebar is-golden">
                                        <use href="/_nuxt/c4a808059f24cba004b538d7e2a1188f.svg#i-icon-level-up"
                                            xlink:href="/_nuxt/c4a808059f24cba004b538d7e2a1188f.svg#i-icon-level-up"></use>
                                    </svg> <span class="bs-c-site-sidebar__list-text">
                                        Level Up Adventure
                                    </span> </span></li>
                            <li class="bs-c-site-sidebar__sublist-item"><span class="bs-c-site-sidebar__sublist-link"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-golden">
                                        <use href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-tournament"
                                            xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-tournament">
                                        </use>
                                    </svg> <span class="bs-c-site-sidebar__list-text">
                                        Slot Wars
                                    </span> </span></li>
                            <li class="bs-c-site-sidebar__sublist-item"><span class="bs-c-site-sidebar__sublist-link"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-golden">
                                        <use href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-tournament"
                                            xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-tournament">
                                        </use>
                                    </svg> <span class="bs-c-site-sidebar__list-text">
                                        Table Wars
                                    </span> </span>
                                </li> -->
              </ul>
            </li>

            <li class="bs-c-site-sidebar__list-item">
              <a
                class="bs-c-site-sidebar__list-link"
                :class="{ 'is-opened': openedIndex === 10 }"
                @click="gamesDropdown(10)"
              >
                <font-awesome-icon
                  :icon="['fas', 'gear']"
                  class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
                  color="#ffffff"
                />
                <span class="bs-c-site-sidebar__list-text"> {{t(`My`)}} {{t(`Account`)}} </span>
                <span class="bs-c-site-sidebar__arrow">
                  <font-awesome-icon
                    :icon="['fas', 'angle-down']"
                    class="icon sprite-icons"
                    style="color: #ffffff"
                  />
                </span>
              </a>
              <ul class="bs-c-site-sidebar__sublist">
                <li
                  class="bs-c-site-sidebar__sublist-item"
                  @click="profileFunction(true, 'dashboard')"
                >
                  <span class="bs-c-site-sidebar__sublist-link is-my-account">
                    <font-awesome-icon
                      :icon="['fas', 'table-columns']"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-my-account"
                      color="#ffffff"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Dashboard`)}}
                    </span>
                  </span>
                </li>
                <li
                  class="bs-c-site-sidebar__sublist-item"
                  @click="profileFunction(true, 'history')"
                >
                  <span class="bs-c-site-sidebar__sublist-link is-my-account">
                    <font-awesome-icon
                      :icon="['fas', 'clock-rotate-left']"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-my-account"
                      color="#ffffff"
                    />
                    <span class="bs-c-site-sidebar__list-text"> {{t(`History`)}} </span>
                  </span>
                </li>
                <li
                  class="bs-c-site-sidebar__sublist-item"
                  @click="profileFunction(true, 'myrewards')"
                >
                  <span class="bs-c-site-sidebar__sublist-link is-my-account">
                    <font-awesome-icon
                      :icon="['fas', 'box-open']"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-my-account"
                      color="#ffffff"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`My`)}} {{t(`Rewards`)}}
                    </span>
                  </span>
                </li>
                <li
                  class="bs-c-site-sidebar__sublist-item"
                  @click="profileFunction(true, 'myprofile')"
                >
                  <span class="bs-c-site-sidebar__sublist-link is-my-account">
                    <font-awesome-icon
                      :icon="['fas', 'user']"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-my-account"
                      color="#ffffff"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`My`)}} {{t(`Profile`)}}
                    </span>
                  </span>
                </li>
              </ul>
            </li>

            <li class="bs-c-site-sidebar__list-item">
              <router-link
                to="/vip-starz-club"
                class="bs-c-site-sidebar__list-link"
              >
                <font-awesome-icon
                  :icon="['fas', 'star']"
                  style="color: #ffffff"
                  class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
                />
                <span class="bs-c-site-sidebar__list-text">{{t(`VIP`)}}</span>
              </router-link>
            </li>
            <li class="bs-c-site-sidebar__list-item">
              <router-link
                to="/refer-earn"
                class="bs-c-site-sidebar__list-link"
              >
                <font-awesome-icon
                  :icon="['fas', 'user']"
                  style="color: #ffffff"
                  class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
                />
                <span class="bs-c-site-sidebar__list-text">{{t(`Refer Earn`)}}</span>
              </router-link>
            </li>
            <li class="bs-c-site-sidebar__list-item">
              <a
                class="bs-c-site-sidebar__list-link"
                :class="{ 'is-opened': openedIndex === 4 }"
                @click="gamesDropdown(4)"
              >
                <font-awesome-icon
                  :icon="['fas', 'circle-question']"
                  style="color: #ffffff"
                  class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
                />
                <span class="bs-c-site-sidebar__list-text"> {{t(`Help`)}} </span>
                <span class="bs-c-site-sidebar__arrow">
                  <font-awesome-icon
                    :icon="['fas', 'angle-down']"
                    class="icon sprite-icons"
                    style="color: #ffffff"
                  />
                </span>
              </a>
              <ul class="bs-c-site-sidebar__sublist">
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/faq"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text"> {{t(`FAQ`)}} </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/about-bitcoin"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`About`)}} {{t(`Bitcoin`)}}
                    </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/btc-to-eur-exchanger"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span
                      class="bs-c-site-sidebar__list-text is-original-font-type"
                    >
                      {{t(`Instant BTC to EUR exchanger`)}}
                    </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/provability-explained"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Provability`)}} {{t(`Explained`)}}
                    </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/play-safe"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Play`)}} {{t(`Safe`)}}
                    </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/complaints"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Complaints`)}}
                    </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/how-to-buy-crypto"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span
                      class="bs-c-site-sidebar__list-text is-original-font-type"
                    >
                      {{t(`How to Buy Crypto`)}}
                    </span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="bs-c-site-sidebar__list-item">
              <a
                class="bs-c-site-sidebar__list-link"
                :class="{ 'is-opened': openedIndex === 5 }"
                @click="gamesDropdown(5)"
              >
                <font-awesome-icon
                  :icon="['fas', 'info']"
                  style="color: #ffffff"
                  class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
                />
                <span class="bs-c-site-sidebar__list-text"> {{t(`More`)}} </span>
                <span class="bs-c-site-sidebar__arrow">
                  <font-awesome-icon
                    :icon="['fas', 'angle-down']"
                    class="icon sprite-icons"
                    style="color: #ffffff"
                  />
                </span>
              </a>
              <ul class="bs-c-site-sidebar__sublist">
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/about-us"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text"> {{t(`About Us`)}} </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/terms"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Terms`)}} &amp; {{t(`Conditions`)}}
                    </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/bonus-terms-and-conditions"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Bonus`)}} {{t(`Terms`)}} &amp; {{t(`Conditions`)}}
                    </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/payments"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text"> {{t(`Payments`)}} </span>
                  </router-link>
                </li>
                <li class="bs-c-site-sidebar__sublist-item">
                  <router-link
                    to="/privacy-policy"
                    class="bs-c-site-sidebar__sublist-link"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      style="color: #ffffff"
                      class="bs-c-site-sidebar__list-icon is-dropdown icon sprite-icons is-arrow"
                    />
                    <span class="bs-c-site-sidebar__list-text">
                      {{t(`Privacy Policy`)}}
                    </span>
                  </router-link>
                </li>

                <!-- <li class="bs-c-site-sidebar__social-icons">
                  <ul class="social-icons">
                    <li class="social-icons__item">
                      <a
                        href="https://t.me/bitstarzcasino"
                        rel="nofollow"
                        target="_blank"
                        class="social-icons__icon is-telegram"
                      ></a>
                    </li>
                    <li class="social-icons__item">
                      <a
                        href="https://twitter.com/bitstarzinsider"
                        rel="nofollow"
                        target="_blank"
                        class="social-icons__icon is-twitter"
                      ></a>
                    </li>
                    <li class="social-icons__item">
                      <a
                        href="https://vimeo.com/user102651714"
                        rel="nofollow"
                        target="_blank"
                        class="social-icons__icon is-vimeo"
                      ></a>
                    </li>
                  </ul>
                </li> -->
              </ul>
            </li>

            <!-- change lnaguege -->

            <li class="bs-c-site-sidebar__list-item">
              <a
                class="bs-c-site-sidebar__list-link"
                :class="{ 'is-opened': openedIndex === 6 }"
                @click="gamesDropdown(6)"
              >
                <div v-if="selectLangauge == 'en' || !selectLangauge">
                  <img
                    src="../assets/flags/gb.png"
                    alt="gb country flag"
                    class="c-flag-icon bs-c-site-sidebar__list-icon"
                  />
                  <span class="bs-c-site-sidebar__list-text"> English </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                  "
                  v-if="selectLangauge == 'fr'"
                >
                  <svg
                    viewBox="0 0 64 64"
                    height="25px"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m1.9 32c0 13.1 8.4 24.2 20 28.3v-56.6c-11.6 4.1-20 15.2-20 28.3z"
                      fill="#428bc1"
                    />
                    <path
                      d="m61.9 32c0-13.1-8.3-24.2-20-28.3v56.6c11.7-4.1 20-15.2 20-28.3"
                      fill="#ed4c5c"
                    />
                    <path
                      d="m21.9 60.3c3.1 1.1 6.5 1.7 10 1.7s6.9-.6 10-1.7v-56.6c-3.1-1.1-6.4-1.7-10-1.7s-6.9.6-10 1.7z"
                      fill="#fff"
                    />
                  </svg>
                  <span class="bs-c-site-sidebar__list-text"> French </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                  "
                  v-if="selectLangauge == 'de'"
                >
                  <svg
                    viewBox="0 0 64 64"
                    height="25px"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m31.9 2c-13.1 0-24.2 8.4-28.3 20h56.6c-4.1-11.6-15.2-20-28.3-20z"
                      fill="#3e4347"
                    />
                    <path
                      d="m31.9 62c13.1 0 24.2-8.3 28.3-20h-56.6c4.1 11.7 15.2 20 28.3 20z"
                      fill="#ffe62e"
                    />
                    <path
                      d="m3.6 22c-1.1 3.1-1.7 6.5-1.7 10s.6 6.9 1.7 10h56.6c1.1-3.1 1.7-6.5 1.7-10s-.6-6.9-1.7-10z"
                      fill="#ed4c5c"
                    />
                  </svg>

                  <span class="bs-c-site-sidebar__list-text"> German </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                  "
                  v-if="selectLangauge == 'pt'"
                >
                  <img
                    src="../assets/flags/br.png"
                    alt="br country flag"
                    class="c-flag-icon bs-c-site-sidebar__list-icon"
                  />
                  <span class="bs-c-site-sidebar__list-text"> Portguese </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                  "
                  v-if="selectLangauge == 'tr'"
                >
                  <svg
                    viewBox="0 0 64 64"
                    height="25px"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="32" cy="32" fill="#ed4c5c" r="30" />
                    <g fill="#fff">
                      <path
                        d="m41.3 39 .1-5.4-5.4-1.6 5.4-1.6-.1-5.4 3.3 4.3 5.4-1.6-3.3 4.3 3.3 4.3-5.4-1.6z"
                      />
                      <path
                        d="m33.2 44c-6.6 0-11.9-5.4-11.9-12s5.3-12 11.9-12c2.5 0 4.8.8 6.8 2.1-2.7-3.1-6.7-5.1-11.2-5.1-8.2 0-14.8 6.7-14.8 15s6.6 15 14.8 15c4.5 0 8.5-2 11.2-5.1-1.9 1.3-4.2 2.1-6.8 2.1"
                      />
                    </g>
                  </svg>
                  <span class="bs-c-site-sidebar__list-text"> Turkish </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                  "
                  v-if="selectLangauge == 'tn'"
                >
                  <svg
                    viewBox="0 0 64 64"
                    height="25px"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="32" cy="32" fill="#ed4c5c" r="30" />
                    <circle cx="32" cy="32" fill="#fff" r="17.3" />
                    <circle cx="32" cy="32" fill="#ed4c5c" r="13" />
                    <circle cx="35.5" cy="32" fill="#fff" r="10.4" />
                    <path
                      d="m38.4 32 3.4-4.6-5.4 1.8-3.3-4.6v5.7l-5.4 1.7 5.4 1.7v5.7l3.3-4.6 5.4 1.8z"
                      fill="#ed4c5c"
                    />
                  </svg>
                  <span class="bs-c-site-sidebar__list-text"> Tunisian </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                  "
                  v-if="selectLangauge == 'ru'"
                >
                  <img
                    src="../assets/flags/ru.png"
                    alt="ru country flag"
                    class="c-flag-icon bs-c-site-sidebar__list-icon"
                  />
                  <span class="bs-c-site-sidebar__list-text"> Russian </span>
                </div>
                <span class="bs-c-site-sidebar__arrow">
                  <font-awesome-icon
                    :icon="['fas', 'angle-down']"
                    class="icon sprite-icons"
                    style="color: #ffffff"
                  />
                </span>
              </a>

              <ul class="bs-c-lang-selector">

                <li
                  @click="$changeLanguage('en')"
                  class="bs-c-lang-selector__item is-active-locale"
                >
                  <span
                    aria-current="page"
                    class="bs-c-site-sidebar__sublist-link nuxt-link-exact-active nuxt-link-active"
                    title="English"
                    >
                    <img
                      src="../assets/flags/gb.png"
                      alt="gb country flag"
                      class="c-flag-icon bs-c-site-sidebar__list-icon"
                    />
                    <span class="bs-c-site-sidebar__list-text">English</span>

                    <em
                      v-if="selectLangauge == 'en'"
                      class="bs-c-site-sidebar__list-icon is-checkmark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon sprite-icons"
                      >
                        <use
                          href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                        ></use>
                      </svg>
                    </em>
                  </span>
                </li>

                <li
                  @click="$changeLanguage('ru')"
                  class="bs-c-lang-selector__item"
                >
                  <span class="bs-c-site-sidebar__sublist-link" title="Русский"
                    ><img
                      src="../assets/flags/ru.png"
                      alt="ru country flag"
                      class="c-flag-icon bs-c-site-sidebar__list-icon"
                    />
                    <span class="bs-c-site-sidebar__list-text">Russian</span>
                    <em
                      v-if="selectLangauge == 'ru'"
                      class="bs-c-site-sidebar__list-icon is-checkmark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon sprite-icons"
                      >
                        <use
                          href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                        ></use>
                      </svg>
                    </em>
                  </span>
                </li>

                <li
                  @click="$changeLanguage('fr')"
                  class="bs-c-lang-selector__item"
                >
                  <span class="bs-c-site-sidebar__sublist-link" title="中文">
                    <svg
                      viewBox="0 0 64 64"
                      height="25px"
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m1.9 32c0 13.1 8.4 24.2 20 28.3v-56.6c-11.6 4.1-20 15.2-20 28.3z"
                        fill="#428bc1"
                      />
                      <path
                        d="m61.9 32c0-13.1-8.3-24.2-20-28.3v56.6c11.7-4.1 20-15.2 20-28.3"
                        fill="#ed4c5c"
                      />
                      <path
                        d="m21.9 60.3c3.1 1.1 6.5 1.7 10 1.7s6.9-.6 10-1.7v-56.6c-3.1-1.1-6.4-1.7-10-1.7s-6.9.6-10 1.7z"
                        fill="#fff"
                      />
                    </svg>
                    <span
                      style="margin-left: 20px"
                      class="bs-c-site-sidebar__list-text"
                      >french</span
                    >
                    <em
                      v-if="selectLangauge == 'fr'"
                      class="bs-c-site-sidebar__list-icon is-checkmark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon sprite-icons"
                      >
                        <use
                          href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                        ></use>
                      </svg>
                    </em>
                  </span>
                </li>

                <li
                  @click="$changeLanguage('de')"
                  class="bs-c-lang-selector__item"
                >
                  <span class="bs-c-site-sidebar__sublist-link" title="日本語">
                    <svg
                      viewBox="0 0 64 64"
                      height="25px"
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m31.9 2c-13.1 0-24.2 8.4-28.3 20h56.6c-4.1-11.6-15.2-20-28.3-20z"
                        fill="#3e4347"
                      />
                      <path
                        d="m31.9 62c13.1 0 24.2-8.3 28.3-20h-56.6c4.1 11.7 15.2 20 28.3 20z"
                        fill="#ffe62e"
                      />
                      <path
                        d="m3.6 22c-1.1 3.1-1.7 6.5-1.7 10s.6 6.9 1.7 10h56.6c1.1-3.1 1.7-6.5 1.7-10s-.6-6.9-1.7-10z"
                        fill="#ed4c5c"
                      />
                    </svg>
                    <span
                      style="margin-left: 20px"
                      class="bs-c-site-sidebar__list-text"
                      >German</span
                    >
                    <em
                      v-if="selectLangauge == 'de'"
                      class="bs-c-site-sidebar__list-icon is-checkmark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon sprite-icons"
                      >
                        <use
                          href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                        ></use>
                      </svg>
                    </em>
                  </span>
                </li>

                <li
                  @click="$changeLanguage('pt')"
                  class="bs-c-lang-selector__item"
                >
                  <span
                    class="bs-c-site-sidebar__sublist-link"
                    title="Português"
                    ><img
                      src="../assets/flags/br.png"
                      alt="br country flag"
                      class="c-flag-icon bs-c-site-sidebar__list-icon"
                    />
                    <span class="bs-c-site-sidebar__list-text">Português</span>
                    <em
                      v-if="selectLangauge == 'pt'"
                      class="bs-c-site-sidebar__list-icon is-checkmark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon sprite-icons"
                      >
                        <use
                          href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                        ></use>
                      </svg>
                    </em>
                  </span>
                </li>

                <li
                  @click="$changeLanguage('tn')"
                  class="bs-c-lang-selector__item"
                >
                  <span
                    class="bs-c-site-sidebar__sublist-link"
                    title="Português"
                  >
                    <svg
                      viewBox="0 0 64 64"
                      height="25px"
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="32" cy="32" fill="#ed4c5c" r="30" />
                      <circle cx="32" cy="32" fill="#fff" r="17.3" />
                      <circle cx="32" cy="32" fill="#ed4c5c" r="13" />
                      <circle cx="35.5" cy="32" fill="#fff" r="10.4" />
                      <path
                        d="m38.4 32 3.4-4.6-5.4 1.8-3.3-4.6v5.7l-5.4 1.7 5.4 1.7v5.7l3.3-4.6 5.4 1.8z"
                        fill="#ed4c5c"
                      />
                    </svg>

                    <span
                      style="margin-left: 20px"
                      class="bs-c-site-sidebar__list-text"
                      >Tunisian</span
                    >
                    <em
                      v-if="selectLangauge == 'tn'"
                      class="bs-c-site-sidebar__list-icon is-checkmark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon sprite-icons"
                      >
                        <use
                          href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                        ></use>
                      </svg>
                    </em>
                  </span>
                </li>

                <li
                  @click="$changeLanguage('tr')"
                  class="bs-c-lang-selector__item"
                >
                  <span
                    class="bs-c-site-sidebar__sublist-link"
                    title="Português"
                  >
                    <svg
                      viewBox="0 0 64 64"
                      height="25px"
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="32" cy="32" fill="#ed4c5c" r="30" />
                      <g fill="#fff">
                        <path
                          d="m41.3 39 .1-5.4-5.4-1.6 5.4-1.6-.1-5.4 3.3 4.3 5.4-1.6-3.3 4.3 3.3 4.3-5.4-1.6z"
                        />
                        <path
                          d="m33.2 44c-6.6 0-11.9-5.4-11.9-12s5.3-12 11.9-12c2.5 0 4.8.8 6.8 2.1-2.7-3.1-6.7-5.1-11.2-5.1-8.2 0-14.8 6.7-14.8 15s6.6 15 14.8 15c4.5 0 8.5-2 11.2-5.1-1.9 1.3-4.2 2.1-6.8 2.1"
                        />
                      </g>
                    </svg>

                    <span
                      style="margin-left: 20px"
                      class="bs-c-site-sidebar__list-text"
                      >Turkish</span
                    >
                    <em
                      v-if="selectLangauge == 'tr'"
                      class="bs-c-site-sidebar__list-icon is-checkmark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon sprite-icons"
                      >
                        <use
                          href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                        ></use>
                      </svg>
                    </em>
                  </span>
                </li>

              </ul>
              <ul class="bs-c-site-sidebar__sublist"></ul>
            </li>
            <li
              v-if="!isAuthicate"
              @click="logout()"
              class="bs-c-site-sidebar__list-item"
            >
              <router-link to="/" class="bs-c-site-sidebar__list-link">
                <font-awesome-icon
                  :icon="['fas', 'right-from-bracket']"
                  class="bs-c-site-sidebar__list-icon icon sprite-icons"
                  color="#ffffff"
                />
                <span class="bs-c-site-sidebar__list-text">{{t(`Log Out`)}}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--deposit Modal -->
   
    <DepositModal/>

    <!--withdraw Modal -->
<WithDrawalModal/>

    
    <!-- // profile dashoboiad -->
    <div :style="{ display: profile }">
      <div>
        <div
          aria-expanded="true"
          data-modal="account-modal"
          class="bs-c-account-modal v--modal-overlay"
        >
          <div class="v--modal-background-click">
            <div class="v--modal-top-right"></div>
            <div
              role="dialog"
              aria-modal="true"
              class="v--modal-box bs-c-account-modal__box bs-c-account-modal__AccountDashboard"
              style="top: 245px; left: 459px; width: 600px; height: 300px;"
            >
              <div
                class="bs-c-account-modal__close"
                @click="profileFunction(false, 'none')"
              ></div>
              <div class="bs-c-account-modal__body">
                <div style="" class="bs-c-account-menu sidebarhide">
                  <div class="bs-c-account-info">
                    <div class="bs-c-account-info__username">IPunisherI</div>
                    <div
                      data-cy="cy-piggy-bank-progress__bar-wrapper"
                      class="c-piggy-bank-progress__bar-wrapper"
                    >
                      <div
                        data-cy="cy-piggy-bank-progress__bar"
                        class="c-piggy-bank-progress__bar"
                      >
                        <div
                          data-cy="cy-piggy-bank-progress__bar-icon"
                          class="c-piggy-bank-progress__bar-icon"
                        >
                          <img
                            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/intact.a4fe977.png"
                            alt="piggy"
                            data-cy="cy-piggy-bank-progress__bar-img"
                            class="c-piggy-bank-progress__bar-img"
                          />
                        </div>
                        <div
                          data-cy="cy-piggy-bank-progress__bar-progress"
                          class="c-piggy-bank-progress__bar-progress"
                          style="--progress: 0"
                        ></div>
                        <strong
                          data-cy="cy-piggy-bank-progress__bar-text"
                          class="c-piggy-bank-progress__bar-text"
                        >
                          {{userData?.data?.bonus}}
                          <span style="font-size: 9px">{{ userData?.data?.currency }}</span>

                        </strong>
                      </div>
                    </div>
                    <div class="currencies-dropdown is-sidebar">
                      <div
                        data-cy="cy-header-currency"
                        class="currencies-dropdown__current"
                      >
                      {{ userData && userData.data && userData.data.amount ? (userData.data.amount.toFixed(2)) : 0 }}
        <span style="font-size: 12px">{{ userData?.data?.currency }}</span>
                      </div>
                    </div>
                    <div class="bs-c-account-info__buttons">
                      <button
                        @click="openModal2"
                        class="c-button is-full-width color-light-purple size-m"
                      >
                        <span>{{t(`Cash out`)}}</span>
                      </button>
                      <button
                        @click="openModal1"
                        class="c-button is-full-width size-m"
                      >
                        <span>{{t(`Deposit`)}}</span>
                      </button>
                    </div>
                  </div>
                  <ul class="bs-c-account-menu-list">
                    <li
                      class="bs-c-account-menu-list__item"
                      :class="{ 'is-active': isActive === 0 }"
                      @click="profileFunction(true, 'dashboard')"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'table-columns']"
                        style="color: #ffffff"
                        class="bs-c-account-menu-list__icon icon sprite-my-account icon-dashboard"
                      />
                      <span class="bs-c-account-menu-list__title"
                        >{{t(`Dashboard`)}}</span
                      >
                    </li>
                    <li
                      class="bs-c-account-menu-list__item"
                      :class="{ 'is-active': isActive === 1 }"
                      @click="profileFunction(true, 'history')"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'clock-rotate-left']"
                        style="color: #ffffff"
                        class="bs-c-account-menu-list__icon icon sprite-my-account icon-dashboard"
                      />
                      <span class="bs-c-account-menu-list__title">{{t(`History`)}}</span>
                    </li>
                    <li
                      class="bs-c-account-menu-list__item"
                      :class="{ 'is-active': isActive === 2 }"
                      @click="profileFunction(true, 'myrewards')"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'box-open']"
                        style="color: #ffffff"
                        class="bs-c-account-menu-list__icon icon sprite-my-account icon-dashboard"
                      />
                      <span class="bs-c-account-menu-list__title"
                        >{{t(`My`)}} {{t(`Rewards`)}}</span
                      >
                    </li>
                    <li
                      class="bs-c-account-menu-list__item"
                      :class="{ 'is-active': isActive === 4 }"
                      @click="profileFunction(true, 'myprofile')"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'user']"
                        style="color: #ffffff"
                        class="bs-c-account-menu-list__icon icon sprite-my-account icon-dashboard"
                      />
                      <span class="bs-c-account-menu-list__title"
                        >{{t(`My`)}} {{t(`Profile`)}}</span
                      >
                    </li>
                  </ul>
                </div>

                <div
                  class="bs-c-account-modal__content-wrap"
                  :style="{ display: dashboard }"
                >
                  <div
                    id="account-modal-container"
                    class="bs-c-account-modal__content__inner"
                  >
                    <div class="bs-c-account-modal__content-header">
                      <h2 class="bs-c-account-modal__content-title">
                        <span>{{t(`Dashboard`)}}</span>
                      </h2>
                    </div>
                    <div class="bs-c-account-modal__content">
                      <div class="bs-c-account-dashboard">
                        <div class="bs-c-widgets-wrapper">
                          <div class="bs-c-widget-box bs-c-account-start">
                            <div class="bs-c-widget-box__header">
                              <span class="bs-c-widget-box__title">
                                {{t(`getting started`)}} (530%)
                              </span>
                              <div
                                class="bs-c-account-start__progressbar-container"
                              >
                                <div
                                  class="bs-c-account-start__progressbar"
                                  style="width: 50%"
                                ></div>
                              </div>
                            </div>
                            <ul class="bs-c-account-start__list">
                              <li class="bs-c-account-list__item disabled">
                                <div class="bs-c-account-list__item-left">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-check']"
                                    style="color: #959ca7"
                                    class="bs-c-account-list__item-icon--left icon sprite-my-account"
                                  />
                                </div>
                                <div
                                  class="bs-c-account-list__item-title-container"
                                >
                                  <span
                                    class="bs-c-account-list__item-title bs-c-account-list__item-title-main"
                                    >{{t(`Open`)}} {{t(`an`)}} {{t(`account`)}}</span
                                  >
                                </div>
                                <div
                                  class="bs-c-account-list__item-right"
                                ></div>
                              </li>
                              <li class="bs-c-account-list__item disabled">
                                <div class="bs-c-account-list__item-left">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-check']"
                                    style="color: #959ca7"
                                    class="bs-c-account-list__item-icon--left icon sprite-my-account"
                                  />
                                </div>
                                <div
                                  class="bs-c-account-list__item-title-container"
                                >
                                  <span
                                    class="bs-c-account-list__item-title bs-c-account-list__item-title-main"
                                    >{{t(`Verify`)}} {{t(`email`)}} {{t(`address`)}}</span
                                  >
                                </div>
                                <div
                                  class="bs-c-account-list__item-right"
                                ></div>
                              </li>
                              <li class="bs-c-account-list__item" 
                              @click="openModal1"
                              
                              >
                                <div class="bs-c-account-list__item-left">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-plus']"
                                    style="color: #199400"
                                    class="bs-c-account-list__item-icon--left icon sprite-my-account"
                                  />
                                </div>
                                <div
                                  class="bs-c-account-list__item-title-container"
                                >
                                  <span
                                    class="bs-c-account-list__item-title bs-c-account-list__item-title-main"
                                    >{{t(`Make a first deposit`)}}</span
                                  >
                                </div>
                                <div class="bs-c-account-list__item-right">
                                  <font-awesome-icon
                                    :icon="['fas', 'chevron-right']"
                                    style="color: #000000"
                                    class="bs-c-account-list__item-icon--right icon sprite-icons is-icon-right"
                                  />
                                </div>
                              </li>
                              <li class="bs-c-account-list__item"   @click="openModal1">
                                <div class="bs-c-account-list__item-left">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-plus']"
                                    style="color: #199400"
                                    class="bs-c-account-list__item-icon--left icon sprite-my-account"
                                  />
                                </div>
                                <div
                                  class="bs-c-account-list__item-title-container"
                                >
                                
                                  <span
                                 
                                    class="bs-c-account-list__item-title bs-c-account-list__item-title-main"
                                    >{{t(`Play with real money`)}}</span
                                  >
                                </div>
                                <div class="bs-c-account-list__item-right">
                                  <font-awesome-icon
                                    :icon="['fas', 'chevron-right']"
                                    style="color: #000000"
                                    class="bs-c-account-list__item-icon--right icon sprite-icons is-icon-right"
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="bs-c-widget-box is-balance">
                            <span class="bs-c-widget-box__title">
                              {{t(`Balance`)}}
                            </span>
                            <span class="bs-c-widget-balance__amount">
                                {{ userData && userData.data && userData.data.amount ? (userData.data.amount.toFixed(2)) : 0 }}
        <span style="font-size: 14px">{{ userData?.data?.currency }}</span>

                            </span>
                            <div class="bs-c-widget-balance__buttons">
                              <button
                                @click="openModal1"
                                class="c-button is-full-width size-l"
                              >
                                <span>{{t(`Deposit`)}}</span>
                              </button>
                            </div>
                          </div>
                          <div
                            class="bs-c-widget-box bs-c-account-my-promotions"
                          >
                            <div class="bs-c-widget-box__header">
                              <span class="bs-c-widget-box__title">
                                {{t(`My`)}} {{t(`Promotions`)}}
                              </span>
                              <span @click="navigateToPromotions" class="bs-c-widget-box__link">
                                {{t(`View all`)}}
                              </span>
                            </div>
                            <ul
                           
                            class="bs-c-account-my-promotions__list">
                              <li 
                              v-for="(item, index) in Promotions.slice(0, 3)" 
                                        :key="index"
                              class="bs-c-account-list__item">
                                <div class="bs-c-account-list__item-left">
                                  <font-awesome-icon
                                    :icon="['fas', 'trophy']"
                                    style="color: #005eff"
                                    class="bs-c-account-list__item-icon--left icon sprite-icons icon-tournament"
                                  />
                                </div>
                                <div
                                  class="bs-c-account-list__item-title-container"
                                >
                                  <span
                                    class="bs-c-account-list__item-title bs-c-account-list__item-title-main"
                                    >{{t(item.category
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase()))}}</span
                      
                                  >
                                  <div
                                    class="bs-c-account-my-promotions__subtitle bs-c-account-list__item-subtitle"
                                  >
                                    <span
                                      class="bs-c-account-my-promotions__subtitle-status"
                                      >{{t(`Active`)}}</span
                                    >
                                    <div class="countdown-timer is-inline">
                                      <span class="countdown-timer__value"
                                        >
                                        {{ formatDate(item?.end_date) }}
                                       </span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="bs-c-account-list__item-right">
                                  <button
                                  @click="navigateToPromotions"
                                    class="c-button bs-c-account-list__item-right-btn is-full-width size-s"
                                  >
                                    <span>{{t(`More`)}} {{t(`info`)}}</span>
                                  </button>
                                </div>
                              </li>
                              <!-- <li class="bs-c-account-list__item">
                                <div class="bs-c-account-list__item-left">
                                  <font-awesome-icon
                                    :icon="['fas', 'trophy']"
                                    style="color: #005eff"
                                    class="bs-c-account-list__item-icon--left icon sprite-icons icon-tournament"
                                  />
                                </div>
                                <div
                                  class="bs-c-account-list__item-title-container"
                                >
                                  <span
                                    class="bs-c-account-list__item-title bs-c-account-list__item-title-main"
                                    >{{t(`Slots`)}} {{t(`Wars`)}}</span
                                  >
                                  <div
                                    class="bs-c-account-my-promotions__subtitle bs-c-account-list__item-subtitle"
                                  >
                                    <span
                                      class="bs-c-account-my-promotions__subtitle-status"
                                      >{{t(`Active`)}}</span
                                    >
                                    <div class="countdown-timer is-inline">
                                      <span class="countdown-timer__value"
                                        ><span
                                          class="countdown-timer__value-block is-day"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >4</span
                                          >
                                          d
                                        </span>
                                        <span
                                          class="countdown-timer__value-block"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >23</span
                                          >
                                          :
                                        </span>
                                        <span
                                          class="countdown-timer__value-block"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >57</span
                                          >
                                          :
                                        </span>
                                        <span
                                          class="countdown-timer__value-block"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >41</span
                                          >
                                        </span></span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="bs-c-account-list__item-right">
                                  <button
                                    class="c-button bs-c-account-list__item-right-btn is-full-width size-s"
                                  >
                                    <span>{{t(`More`)}} {{t(`info`)}}</span>
                                  </button>
                                </div>
                              </li>
                              <li class="bs-c-account-list__item">
                                <div class="bs-c-account-list__item-left">
                                  <font-awesome-icon
                                    :icon="['fas', 'trophy']"
                                    style="color: #005eff"
                                    class="bs-c-account-list__item-icon--left icon sprite-icons icon-tournament"
                                  />
                                </div>
                                <div
                                  class="bs-c-account-list__item-title-container"
                                >
                                  <span
                                    class="bs-c-account-list__item-title bs-c-account-list__item-title-main"
                                    >{{t(`Table`)}} {{t(`Wars`)}}</span
                                  >
                                  <div
                                    class="bs-c-account-my-promotions__subtitle bs-c-account-list__item-subtitle"
                                  >
                                    <span
                                      class="bs-c-account-my-promotions__subtitle-status"
                                      >{{t(`Active`)}}</span
                                    >
                                    <div class="countdown-timer is-inline">
                                      <span class="countdown-timer__value"
                                        ><span
                                          class="countdown-timer__value-block is-day"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >6</span
                                          >
                                          d
                                        </span>
                                        <span
                                          class="countdown-timer__value-block"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >10</span
                                          >
                                          :
                                        </span>
                                        <span
                                          class="countdown-timer__value-block"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >57</span
                                          >
                                          :
                                        </span>
                                        <span
                                          class="countdown-timer__value-block"
                                          ><span
                                            class="countdown-timer__value-time"
                                            >41</span
                                          >
                                        </span></span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="bs-c-account-list__item-right">
                                  <button
                                    class="c-button bs-c-account-list__item-right-btn is-full-width size-s"
                                  >
                                    <span>{{t(`More`)}} {{t(`info`)}}</span>
                                  </button>
                                </div>
                              </li> -->
                            </ul>
                          </div>
                          <div class="bs-c-widget-box bs-c-account-games">

                            <UserWallet/>

                          </div>
                          <div class="bs-c-widget-box is-secure-account">
                            <div class="bs-c-widget-secure-account__header">
                              <font-awesome-icon
                                :icon="['fas', 'shield']"
                                style="color: #005eff"
                                class="bs-c-widget-secure-account__icon icon sprite-my-account"
                              />

                              <h3 class="bs-c-widget-secure-account__title">
                                {{t(`Secure your account`)}}
                              </h3>
                            </div>
                            <span class="bs-c-widget-secure-account__text"
                              >{{t(`We recommend you to set up a two-factor
                              authentication and verify your phone number`)}}.</span
                            >
                            <button
                              class="c-button bs-c-widget-secure-account__btn is-secondary is-full-width size-l"
                            >
                              <span>{{t(`Check account security`)}}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="bs-c-account-modal__content-wrap"
                  :style="{ display: history }"
                >
                  <div
                    id="account-modal-container"
                    class="bs-c-account-modal__content__inner"
                  >
                    <div class="bs-c-account-modal__content-header">
                      <h2 class="bs-c-account-modal__content-title">
                        <span>{{t(`History`)}}</span>
                      </h2>
                    </div>
                    <div class="bs-c-account-modal__content">
                      <div class="bs-c-tabs-swiper">
                        <div
                          class="bs-c-tabs-swiper__item"
                          :class="{ 'is-active': activeTab === 'Transactions' }"
                          @click="setActiveTab('Transactions')"
                        >
                          {{t(`Transactions`)}}
                        </div>
                        <div
                          class="bs-c-tabs-swiper__item"
                          :class="{ 'is-active': activeTab === 'Bets' }"
                          @click="setActiveTab('Bets')"
                        >
                          {{t(`Bets`)}}
                        </div>
                        <!-- <div
                          class="bs-c-tabs-swiper__item"
                          :class="{ 'is-active': activeTab === 'SecondaryBets' }"
                          @click="setActiveTab('SecondaryBets')"
                        >
                          {{t(`Secondary`)}} {{t(`Bets`)}}
                        </div> -->
                        <div
                          class="bs-c-tabs-swiper__item"
                          :class="{ 'is-active': activeTab === 'Rewards' }"
                          @click="setActiveTab('Rewards')"
                        >
                          {{t(`Rewards`)}}
                        </div>
                      </div>
                      <div
                        v-if="activeTab === 'Transactions'"
                        class="bs-c-transactions"
                      >
                        <div class="bs-c-transactions__content">
                          <div class="bs-c-transactions__inner-content">
                            <div
                              v-if="transactions.length === 0"
                              class="bs-c-empty-transactions"
                            >
                              <div class="bs-c-empty-transactions__content">
                                <span
                                  class="bs-c-empty-transactions__content__title"
                                >
                                  {{t(`There's nothing here yet`)}}.
                                </span>
                                <span
                                  class="bs-c-empty-transactions__content__text"
                                >
                                  {{t(`Transactions will show up when you deposit or cash out`)}}.
                                </span>
                              </div>
                            </div>
                            <div v-else>
                              <div
                                class="transaction-table"
                                style="
                                  overflow: scroll;
                                  color: black;
                                  padding: 15px;
                                  font-weight: bold;
                                "
                              >
                                <table style="width: 100%">
                                  <thead>
                                    <tr
                                      class="transaction-row"
                                      style="
                                        font-weight: bold;
                                        text-align: center;
                                      "
                                    >
                                      <th
                                        style="
                                          min-width: 150px;
                                          text-align: left;
                                        "
                                        class="transaction-cell"
                                      >
                                        {{ t(`Transaction`) }} {{ t(`id`) }}
                                      </th>
                                      <th
                                        style="min-width: 100px"
                                        class="transaction-cell"
                                      >
                                        {{ t(`Type`) }}
                                      </th>
                                      <th
                                        style="min-width: 100px"
                                        class="transaction-cell"
                                      >
                                        {{ t(`Amount`) }}
                                      </th>
                                      <th
                                        style="min-width: 100px"
                                        class="transaction-cell"
                                      >
                                        {{ t(`Status`) }}
                                      </th>
                                      <th
                                        style="min-width: 100px"
                                        class="transaction-cell"
                                      >
                                        {{ t(`Date`) }}
                                      </th>
                                      <th
                                        style="
                                          min-width: 150px;
                                          text-align: right;
                                        "
                                        class="transaction-cell"
                                      >
                                        {{ t(`Admin`) }} {{ t(`Response`) }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        transaction, index
                                      ) in transactions"
                                      :key="index"
                                      style="
                                        font-weight: bold;
                                        padding: 10px;

                                        color: #56546e;

                                        text-align: center;
                                      "
                                      class="transaction-row"
                                    >
                                      <td
                                        style="
                                          min-width: 150px;
                                          font-size: 12px;
                                          text-align: left;
                                        "
                                        class="transaction-cell"
                                      >
                                        {{ transaction?.transaction_id }}
                                      </td>
                                      <td
                                        style="
                                          min-width: 100px;
                                          font-size: 12px;
                                          font-weight: bold;
                                        "
                                        class="transaction-cell"
                                      >
                                        {{ transaction.type }}
                                      </td>
                                      <td
                                        :style="{
                                          minWidth: '100px',
                                          fontWeight: 'bold',
                                          fontSize: '12px',
                                          color:
                                            transaction?.type === 'deposit'
                                              ? '#22c55e'
                                              : '#ef4444',
                                        }"
                                        class="transaction-cell"
                                      >
                                        {{
                                          transaction?.type === "deposit"
                                            ? "+" + transaction?.deposit_amount
                                            : "-" + transaction?.withdraw_amount
                                        }}
                                        {{ transaction?.currency }}
                                      </td>
                                      <td
                                        :style="{
                                          minWidth: '100px',
                                          fontWeight: 'bold',
                                          fontSize: '12px',
                                          color:
                                            transaction.status === 'approved'
                                              ? '#22c55e'
                                              : transaction.status === 'pending'
                                              ? 'orange'
                                              : 'red',
                                        }"
                                        class="transaction-cell"
                                      >
                                        {{ transaction.status }}
                                      </td>
                                      <td
                                        style="
                                          min-width: 100px;
                                          font-size: 12px;
                                        "
                                        class="transaction-cell"
                                      >
                                        {{ formatDateTime(transaction?.initiated_at) }}
                                      </td>
                                      <td
                                        style="
                                          min-width: 150px;
                                          text-align: right;
                                          font-size: 12px;
                                        "
                                        class="transaction-cell"
                                      >
                                        {{
                                          transaction?.admin_response ||
                                          "No admin response"
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="activeTab === 'Bets'" class=" ">
                        <div class=" " style="width: 100%">
                          <!-- <div class="bs-c-header">Bets</div> -->
                          <div class="" style="width: 100%">
                            <div class="">
                              <div
                                v-if="betData.length === 0"
                                style="margin-top:100px"
                                class="bs-c-empty-transactions__content"
                              >
                                <span
                                  class="bs-c-empty-transactions__content__title"
                                >
                                  {{t(`There's nothing here yet`)}}.
                                </span>
                                <span
                                  class="bs-c-empty-transactions__content__text"
                                >
                                  {{t(`Transactions will show up when you deposit or cash out`)}}.
                                </span>
                              </div>
                              <div v-else style="width: 100%" class="bets">
                                <div
                                  class="transaction-table"
                                  style="overflow: scroll"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <thead>
                                      <tr
                                        class="transaction-row"
                                        style="
                                          font-weight: bold;
                                          text-align: center;
                                          justify-content: space-between;
                                        "
                                      >
                                        <th
                                          style="
                                            min-width: 100px;
                                            text-align: left;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{ t(`BetTime`) }}
                                        </th>

                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`EventType`) }}
                                        </th>
                                        <th
                                          style="min-width: 150px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Provider`) }}
                                        </th>
                                        <th
                                          style="min-width: 150px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Name`) }}
                                        </th>
                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Amount`) }}
                                        </th>
                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Win`) }}/{{ t(`Loss`) }}
                                        </th>
                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Result`) }}
                                        </th>
                                        <th
                                          style="
                                            min-width: 100px;
                                            text-align: right;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{ t(`Status`) }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(transaction, index) in betData"
                                        :key="index"
                                        style="
                                          color: #56546e;
                                          padding: 10px;
                                          text-align: center;
                                        "
                                        class="transaction-row"
                                      >
                                        <td
                                          style="
                                            min-width: 100px;
                                            text-align: left;
                                            font-size: 13px;
                                            font-weight: bold;
                                          "
                                          class="transaction-cell"
                                        >
                                        {{ formatDateTime(transaction?.BetTime) }}
                                        </td>

                                        <td
                                          style="font-size: 14px"
                                          class="transaction-cell"
                                        >
                                          {{ transaction?.EventType }}
                                        </td>
                                        <td
                                          style="
                                            min-width: 140px;
                                            font-size: 14px;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction.Provider
                                          }}
                                        </td>
                                        <td
                                          style="
                                            min-width: 150px;
                                            font-size: 14px;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction?.GameName?.slice(
                                              0,
                                              15
                                            ) || "N/A"
                                          }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            color: 'blue',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{transaction?.Amount}}
                                          {{ transaction?.currency }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            color:
                                              transaction.Status === 'running'
                                                ? 'orange'
                                                : transaction.WinLoss === '0'
                                                ? '#ef4444'
                                                : '#4ade80',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction.Status === "running"
                                              ? ""
                                              : transaction.WinLoss !== "0"
                                              ? "+"
                                              : "-"
                                          }}
                                          {{
                                            transaction.Status === "running"
                                              ? 0
                                              : transaction.WinLoss !== "0"
                                              ? transaction?.WinLoss
                                              : transaction?.Amount
                                          }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            color:
                                              transaction.Status === 'running'
                                                ? 'orange'
                                                : transaction.WinLoss === '0'
                                                ? '#ef4444'
                                                : '#4ade80',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction.Status === "running"
                                              ? "Pending"
                                              : transaction.WinLoss !== "0"
                                              ? "Win"
                                              : "Lose"
                                          }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            color: 'yellowgreen',
                                            textAlign: 'right',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{ transaction.Status.toUpperCase() }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div v-if="activeTab === 'SecondaryBets'" class=" ">
                        <div class=" " style="width: 100%">
                          <div class="" style="width: 100%">
                            <div class="">
                              <div
                                v-if="secondaryBets.length === 0"
                                class="bs-c-empty-transactions__content"
                                style="margin-top:100px"
                              >
                                <span
                                  class="bs-c-empty-transactions__content__title"
                                >
                                  {{t(`There's  nothing here yet`)}}.
                                </span>
                                <span
                                  class="bs-c-empty-transactions__content__text"
                                >
                                  {{t(`Transactions will show up when you deposit or cash out`)}}.
                                </span>
                              </div>
                              <div v-else style="width: 100%" class="bets">
                                <div
                                  class="transaction-table"
                                  style="overflow: scroll"
                                >
                                  <table
                                    style="
                                      width: 100%;
                                      border-collapse: collapse;
                                    "
                                  >
                                    <thead>
                                      <tr
                                        class="transaction-row"
                                        style="
                                          font-weight: bold;
                                          text-align: center;
                                          justify-content: space-between;
                                        "
                                      >
                                        <th
                                          style="
                                            min-width: 100px;
                                            text-align: left;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{ t(`BetTime`) }}
                                        </th>

                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Game Type`) }}
                                        </th>
                                        <th
                                          style="min-width: 150px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Provider`) }}
                                        </th>
                                        <th
                                          style="min-width: 150px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Name`) }}
                                        </th>
                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Amount`) }}
                                        </th>
                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Win`) }}/{{ t(`Loss`) }}
                                        </th>
                                        <th
                                          style="min-width: 100px"
                                          class="transaction-cell"
                                        >
                                          {{ t(`Result`) }}
                                        </th>
                                        <th
                                          style="
                                            min-width: 100px;
                                            text-align: right;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{ t(`Status`) }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(transaction, index) in secondaryBets"
                                        :key="index"
                                        style="
                                          color: #56546e;
                                          padding: 10px;
                                          text-align: center;
                                        "
                                        class="transaction-row"
                                      >
                                        <td
                                          style="
                                            min-width: 100px;
                                            text-align: left;
                                            font-size: 13px;
                                            font-weight: bold;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{ transaction?.created_at }}
                                        </td>

                                        <td
                                          style="font-size: 14px"
                                          class="transaction-cell"
                                        >
                                          {{ transaction?.game_type }}
                                        </td>
                                        <td
                                          style="
                                            min-width: 140px;
                                            font-size: 14px;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction.api_provider_name
                                          }}
                                        </td>
                                        <td
                                          style="
                                            min-width: 150px;
                                            font-size: 14px;
                                          "
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction?.game_name?.slice(
                                              0,
                                              15
                                            ) || "N/A"
                                          }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            color: 'blue',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{ transaction?.amount }}
                                          {{ transaction?.currency }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '13px',
                                            color:
                                              transaction.status === 'running'
                                                ? 'orange'
                                                : transaction.win_loss === '0'
                                                ? '#ef4444'
                                                : '#4ade80',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction.status === "running"
                                              ? ""
                                              : transaction.win_loss !== "0"
                                              ? "+"
                                              : "-"
                                          }}
                                          {{
                                            transaction.status === "running"
                                              ? 0
                                              : transaction.win_loss !== "0"
                                              ? transaction?.win_loss
                                              : transaction?.amount
                                          }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            color:
                                              transaction.status === 'running'
                                                ? 'orange'
                                                : transaction.win_loss === '0'
                                                ? '#ef4444'
                                                : '#4ade80',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{
                                            transaction.status === "running"
                                              ? "Pending"
                                              : transaction.win_loss !== "0"
                                              ? "Win"
                                              : "Lose"
                                          }}
                                        </td>
                                        <td
                                          :style="{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            color: 'yellowgreen',
                                            textAlign: 'right',
                                          }"
                                          class="transaction-cell"
                                        >
                                          {{ transaction?.status?.toUpperCase() }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->


                      
                      <div v-if="activeTab === 'Rewards'" class="bs-c-rewards">
                        <div class="bs-c-transactions__content">
                          <div class="bs-c-header"></div>
                          <div class="bs-c-transactions__inner-content">
                            <div
                              v-if="checkData.length === 0"
                              class="bs-c-empty-transactions"
                            >
                              <div class="bs-c-empty-transactions__content">
                                <span
                                  class="bs-c-empty-transactions__content__title"
                                >
                                  {{t(`There's nothing here yet`)}}.
                                </span>
                                <span
                                  class="bs-c-empty-transactions__content__text"
                                >
                                  {{t(`Transactions will show up when you deposit or cash out`)}}.
                                </span>
                              </div>
                            </div>
                            <div
                              v-else
                              style="
                                margin: 5px;
                                padding: 30px;
                                border-radius: 12px;
                                width: 100%;
                              "
                            >
                              <!-- Select Tags for Categories and Sub-Categories -->
                              <div
                                style="
                                  display: flex;
                                  gap: 12px;
                                  margin-bottom: 20px;
                                "
                              >
                                <select
                                  style="
                                    font-size: 14px;
                                    padding: 4px;
                                    border-radius: 4px;
                                    outline: none;
                                  "
                                  v-model="category"
                                >
                                  <option value="">{{ t(`Category`) }}</option>
                                  <option value="user_bonus">
                                    {{ t(`User`) }} {{ t(`Bonus`) }}
                                  </option>
                                  <option value="deposit_bonus">
                                    {{ t(`Deposit`) }} {{ t(`Bonus`) }}
                                  </option>
                                  <option value="bet_bonus">
                                    {{ t(`Bet`) }} {{ t(`Bonus`) }}
                                  </option>
                                </select>
                                <select
                                  style="
                                    font-size: 14px;
                                    padding: 4px;
                                    border-radius: 4px;
                                    outline: none;
                                  "
                                  v-model="sub_category"
                                >
                                  <option value="">
                                    {{ t(`Sub`) }}-{{ t(`Category`) }}
                                  </option>
                                  <option value="first_user">
                                    {{ t(`First`) }} {{ t(`User`) }}
                                  </option>
                                  <option value="first_bet">
                                    {{ t(`First`) }} {{ t(`Bet`) }}
                                  </option>
                                </select>
                                <select
                                  style="
                                    font-size: 14px;
                                    padding: 4px;
                                    border-radius: 4px;
                                    outline: none;
                                  "
                                  v-model="status"
                                >
                                  <option value="all">{{ t(`Status`) }}</option>

                                  <option value="success">
                                    {{ t(`Success`) }}
                                  </option>
                                  <option value="failed">
                                    {{ t(`Failed`) }}
                                  </option>
                                </select>
                              </div>
                              <!-- <div class="spinner" v-if="isLoading"></div> -->

                              <div style="overflow-x: auto; width: 100%">
                                <table style="width: 100%">
                                  <!-- Table Headers -->
                                  <thead>
                                    <tr
                                      style="
                                        font-size: 12px;
                                        white-space: nowrap;
                                      "
                                    >
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                          min-width: 150px;
                                        "
                                      >
                                      
                                        {{ t(`Timestamp`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                        "
                                      >
                                        {{ t(`Category`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                        "
                                      >
                                        {{ t(`Sub`) }} {{ t(`Category`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                        "
                                      >
                                        {{ t(`Reward`) }} {{ t(`Amount`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                        "
                                      >
                                        {{ t(`Bonus`) }} {{ t(`Amount`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                        "
                                      >
                                        {{ t(`Reward`) }} {{ t(`Type`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                          min-width: 150px;
                                        "
                                      >
                                        {{ t(`Description`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                        "
                                      >
                                        {{ t(`Status`) }}
                                      </th>
                                      <th
                                        style="
                                          border: 1px solid #676767;
                                          padding: 4px 8px;
                                          min-width: 200px;
                                        "
                                      >
                                        {{ t(`Rules`) }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody style="color: white">
                                    <!-- Table Rows -->
                                    <tr
                                      v-for="(item, index) in userBonus"
                                      :key="index"
                                      style="color: #0d0d0d; font-size: 14px"
                                    >
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                        "
                                      >
                                      {{ formatDateTime(item?.timestamp) }}

                                      </td>
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                        "
                                      >
                                        {{ item.category }}
                                      </td>
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                        "
                                      >
                                        {{ item.sub_category }}
                                      </td>
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                          color: green;
                                          font-weight: bold;
                                        "
                                      >
                                        {{ item.reward_amount }}
                                        {{ item?.currency }}
                                      </td>
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                          color: green;
                                          font-weight: bold;
                                        "
                                      >
                                        {{ item.bonus_amount }}
                                      </td>
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                        "
                                      >
                                        {{ item.reward_type }}
                                      </td>
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                        "
                                      >
                                        {{ item.description }}
                                      </td>
                                      <td
                                        :style="{
                                          fontWeight: 'bold',
                                          border: '1px solid #676767',
                                          padding: '4px 8px',
                                          color:
                                            item.status === 'failed'
                                              ? '#ef4444'
                                              : '#22c55e',
                                        }"
                                      >
                                        {{ item.status }}
                                      </td>
                                      <td
                                        style="
                                          border: 1px solid #676767;
                                          padding: 8px 8px;
                                        "
                                      >
                                        <p
                                          class="flex-container"
                                          v-html="item?.rules"
                                        ></p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <!-- Pagination Controls -->
                              <div
                                style="
                                  display: flex;
                                  gap: 10px;
                                  justify-content: center;
                                  align-items: center;
                                  margin-top: 20px;
                                "
                              >
                                <button
                                  style="
                                    color: #a8a29e;
                                    background-color: #3b3955;
                                    font-size: 15px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                  @click="prevPage"
                                  :disabled="currentPage === 1"
                                >
                                  &lt;
                                </button>
                                <span style="font-weight: bold">{{
                                  currentPage
                                }}</span>
                                <button
                                  style="
                                    color: #a8a29e;
                                    background-color: #3b3955;
                                    font-size: 15px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                  @click="nextPage"
                                  :disabled="
                                    currentPage === pagination?.totalPages
                                  "
                                >
                                  &gt;
                                </button>
                              </div>
                              <div style="display: flex; justify-content: end">
                                <p style="font-size: 14px">
                                  {{t(`Total`)}} {{t(`Page`)}} :
                                  <span>{{ pagination?.totalPages }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="bs-c-account-modal__content-wrap"
                  :style="{ display: myrewards }"
                >
                  <div
                    id="account-modal-container"
                    class="bs-c-account-modal__content__inner"
                  >
                    <div class="bs-c-account-modal__content-header">
                      <h2
                        class="bs-c-account-modal__content-title has-bonus-button"
                      >
                        <span>{{t(`My`)}} {{t(`Rewards`)}}</span>
                        <button
                          class="c-button bs-c-deposit-code_btn is-secondary size-s"
                        >
                          <span>{{t(`Enter`)}} {{t(`Bonus`)}} {{t(`Code`)}}</span>
                        </button>
                      </h2>
                    </div>
                    <div class="bs-c-account-modal__content" >
                      <div class="bs-c-account-promotions">
                        <div class="promotion_cards is-account">
                          <div
                            class="promotions-section bs-c-account-promotions-container is-account"
                          >
                            <div

                              class="promotions-section__title-wrapper bs-c-account-promotions__header"
                            >
                              <h3
                                class="promotions-section__title bs-c-account-promotions__title"
                              >
                                {{t(`Promotions`)}} &amp; {{t(`Tournaments`)}}
                              </h3>
                            </div>
                            <div class="promotions-section-wrapper">
                           

                              <div>
                              

                                <div class="promotion-mbox">
                                  <Promotions :isActive="false" />
                                </div>
                                <!-- <div class="promotions-grid">
                                  <div
                                    class="promotion-card"
                                    v-for="promotion in Promotions"
                                    :key="promotion._id"
                                  >
                                    <img
                                      :src="promotion.image"
                                      class="card-img-top"
                                      :alt="promotion.category"
                                    />
                                    <div class="card-content">
                                      <h5 class="card-title">
                                        {{ promotion.category }}
                                      </h5>
                                      <p class="card-text">
                                        {{ promotion.description }}
                                      </p>
                                      <p class="card-text">
                                        <strong>Reward:</strong>
                                        {{ promotion.reward_amount }}
                                        {{ promotion.reward_type }}
                                      </p>
                                      <div
                                        style="
                                          display: flex;
                                          align-items: center;
                                          justify-content: space-between;
                                        "
                                      >
                                        <button
                                          @click="openModal1"
                                          class="btn btn-primary"
                                        >
                                          {{t(`Get`)}} {{t(`Bonus`)}}
                                        </button>
                                        <button
                                          @click="navigateToPromotion"
                                          style="font-size: 12px"
                                          class="btn btn-secondary"
                                        >
                                          {{t(`More`)}} {{t(`Details`)}}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="bs-c-account-modal__content-wrap"
                  :style="{ display: myprofile }"
                >
                  <div
                    id="account-modal-container"
                    class="bs-c-account-modal__content__inner"
                  >
                    <div class="bs-c-account-modal__content-header">
                      <h2 class="bs-c-account-modal__content-title">
                        <span>{{t(`My`)}} {{t(`Profile`)}}</span>
                      </h2>
                    </div>
                   
                    <div>
                      <Verfication/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- login -->

    <div :style="{ display: login }">
      <div
        aria-expanded="true"
        data-modal="auth-modal"
        class="c-modal-overlay v--modal-overlay"
        id="auth-modal"
      >

      <!-- login desktop -->
        <div class="v--modal-background-click signuphide" >
          <div class="v--modal-top-right"></div>
          <div
            role="dialog"
            aria-modal="true"
            class="v--modal-box c-modal-overlay-box is-title-missing"
            style="width: 30%; background-color: rgba(128, 128, 128, 0.5)"
          >
            <div class="bs-c-auth-outer" style="margin: auto; margin-top: 10%">
              <div
                data-cy="cy-login-modal-container"
                class="bs-c-auth-wrapper"
                style="display: flex"
              >
                <!-- //login form -->
                <div
                  v-if="!showResendEmail && !showFillOtpPassword"
                  class="bs-c-auth-container"
                  style="
                    margin-right: 20px;
                    height: 350px;
                    background-color: white;
                    border-radius: 20px;
                    padding: 10px;
                  "
                >
                  <header class="bs-c-modal-header">
                    <h3 class="bs-c-modal-header__title">{{t(`Login`)}}</h3>
                  </header>
                  <div class="bs-c-auth-content" style="background: white">
                    <div class="bs-c-auth-login">
                      <div>
                        <div
                          id="modal-login-form"
                          novalidate="novalidate"
                          class="bs-c-auth-login__form"
                        >
                          <div class="bs-c-auth-login-email">
                            <div class="bs-c-deposit__input-wrapper">
                              <input
                                style="padding: 12px; border-radius: 6px"
                                placeholder="Enter Your Username"
                                id="username"
                                v-model="loginUsername"
                                type="text"
                                name="username"
                                data-cy="cy-email-login-modal"
                                required="required"
                              />
                              <!-- <span class="form-input__error">The username is required.</span> -->
                            </div>
                          </div>
                          <div class="bs-c-auth-login-password">
                            <div class="bs-c-deposit__input-wrapper">
                              <div style="position: relative; width: 100%">
                                <input
                                  style="padding: 12px; border-radius: 6px"
                                  :type="passwordVisible ? 'text' : 'password'"
                                  id="password"
                                  v-model="loginPassword"
                                  placeholder="Enter your password"
                                />

                                <p
                                  type="button"
                                  @click="togglePasswordVisibility"
                                  style="
                                    position: absolute;
                                    right: 5px;
                                    top: 15%;
                                    transform: translateY(-50%);
                                    background-color: white;
                                    color: black;
                                    padding: 0px;
                                  "
                                >
                                  <!-- Icon for toggle button, you can use any icon you like -->
                                  <i
                                    style="color: black"
                                    :class="
                                      passwordVisible
                                        ? 'fas fa-eye-slash'
                                        : 'fas fa-eye'
                                    "
                                  ></i>
                                </p>
                              </div>
                              <!-- <span class="form-input__error" >The password field is required.</span> -->
                            </div>
                          </div>
                          <div class="bs-c-auth-login-cta">
                            <div class="bs-c-auth-login-button">
                              <button
                                @click="LoginSubmit"
                                style="margin-top: 10px; width: 100%"
                                class="auth-btn"
                              >
                                <div
                                  class="spinner"
                                  style="margin-top: 0px"
                                  v-if="loginLoader"
                                ></div>
                                <span v-else>{{t(`Log In`)}}</span>
                              </button>
                              <div class="form-top-error-holder"></div>
                            </div>
                            <div
                              @click="ForgetPassword"
                              style="
                                cursor: pointer;
                                margin-top: 10px;
                                text-decoration: underline;
                              "
                              class="bs-c-auth-login-forgot-password"
                            >
                              {{t(`Forgot Password`)}}?
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bs-c-auth-footer">
                      <span class="bs-c-auth-footer__text">
                        {{t(`Don't have an account`)}}?
                      </span>
                      <a
                        @click="CloseLoginOpenSignup"
                        style="cursor: pointer; margin-top: 8px"
                        class="bs-c-auth-footer__link"
                      >
                        {{t(`Sign Up`)}}
                      </a>
                    </div>
                  </div>
                </div>

                <!-- //resend otp -->
                <div
                  v-if="showResendEmail"
                  class="bs-c-auth-container"
                  style="
                    margin-right: 20px;
                    height: 350px;
                    background-color: white;
                    border-radius: 20px;
                    padding: 10px;
                  "
                >
                  <header class="bs-c-modal-header">
                    <h3 class="bs-c-modal-header__title">{{t(`Forget Password`)}}</h3>
                  </header>
                  <div
                    class="bs-c-auth-content"
                    style="background: white; margin-top: 40px"
                  >
                    <div class="bs-c-auth-login">
                      <div>
                        <div
                          id="modal-login-form"
                          novalidate="novalidate"
                          class="bs-c-auth-login__form"
                        >
                          <div class="bs-c-auth-login-email">
                            <div class="bs-c-deposit__input-wrapper">
                              <input
                                style="
                                  padding: 12px;
                                  border-radius: 6px;
                                  width: 100%;
                                  border: 1px solid gray;
                                "
                                placeholder="Enter your register email"
                                id="registerEmail"
                                v-model="registerEmail"
                                type="email"
                                name="registerEmail"
                                data-cy="cy-email-login-modal"
                                required="required"
                              />
                            </div>
                          </div>

                          <div class="bs-c-auth-login-cta">
                            <div class="bs-c-auth-login-button">
                              <button
                                @click="SendOtp"
                                style="width: 100%"
                                class="auth-btn"
                              >
                                <div
                                  class="spinner"
                                  style="margin-top: 0px"
                                  v-if="sendOtpLoader"
                                ></div>
                                <span v-else>{{t(`Send`)}} {{t(`Otp`)}}</span>
                              </button>
                              <div class="form-top-error-holder"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- vefiyr otp and chmage password -->
                <div
                  v-if="showFillOtpPassword"
                  class="bs-c-auth-container"
                  style="
                    margin-right: 20px;
                    height: 350px;
                    background-color: white;
                    border-radius: 20px;
                    padding: 10px;
                  "
                >
                  <header class="bs-c-modal-header">
                    <h3 class="bs-c-modal-header__title">{{t(`Change`)}} {{t(`Password`)}}</h3>
                  </header>
                  <div
                    class="bs-c-auth-content"
                    style="background: white; margin-top: 40px"
                  >
                    <div class="bs-c-auth-login">
                      <div>
                        <div
                          id="modal-login-form"
                          novalidate="novalidate"
                          class="bs-c-auth-login__form"
                        >
                          <div class="bs-c-auth-login-email">
                            <div class="bs-c-deposit__input-wrapper">
                              <input
                                style="
                                  padding: 12px;
                                  border-radius: 6px;
                                  width: 100%;
                                  border: 1px solid gray;
                                "
                                placeholder="Enter your correct otp"
                                id="otp"
                                v-model="otp"
                                type="number"
                                name="otp"
                                data-cy="cy-email-login-modal"
                                required="required"
                              />
                            </div>
                          </div>
                          <div style="position: relative; width: 100%">
                            <input
                              style="padding: 12px; border-radius: 6px"
                              :type="passwordVisible ? 'text' : 'password'"
                              id="newpassword"
                              v-model="newPassword"
                              placeholder="Enter New password"
                            />

                            <p
                              type="button"
                              @click="togglePasswordVisibility"
                              style="
                                position: absolute;
                                right: 5px;
                                top: 15%;
                                transform: translateY(-50%);
                                background-color: white;
                                color: black;
                                padding: 0px;
                              "
                            >
                              <!-- Icon for toggle button, you can use any icon you like -->
                              <i
                                style="color: black"
                                :class="
                                  passwordVisible
                                    ? 'fas fa-eye-slash'
                                    : 'fas fa-eye'
                                "
                              ></i>
                            </p>
                          </div>

                          <div class="bs-c-auth-login-cta">
                            <div class="bs-c-auth-login-button">
                              <button
                                @click="UpdatePassword"
                                style="width: 100%"
                                class="auth-btn"
                              >
                                <div
                                  class="spinner"
                                  style="margin-top: 0px"
                                  v-if="verifyOtpLoader"
                                ></div>
                                <span v-else>{{t(`Update`)}} {{t(`Password`)}}</span>
                              </button>
                              <div class="form-top-error-holder"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  style="
                    color: white;
                    font-size: 20px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                  "
                  @click="loginFunction(false)"
                >
                  <font-awesome-icon :icon="['fas', 'x']" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- login-mobile -->
        <div class="signupMobile">
          <div
              v-if="!showOtherDetails"
              data-cy="cy-sign-up-step-wrapper "
              class="c-v-signup-step-wrapper "
              style="width:100%;border-radius:0px"
            >
              <section data-cy="cy-sign-up-email-step" class="c-v-auth-step">
                <header class="c-v-auth-step-header" style="background-color:#1B1928">
                  <span
                    data-cy="cy-sign-up-email-step-title"
                    class="c-v-auth-step-header__title"
                    style="color:white"
                    v-if="!showResendEmail && !showFillOtpPassword"
                  >
                   {{t(`Login`)}}
                  </span>
                  <span
                    data-cy="cy-sign-up-email-step-title"
                    class="c-v-auth-step-header__title"
                    style="color:white"
                    v-if="showResendEmail"
                  >
                  {{t(`Forget Password`)}}
                  </span>
                  <span
                    data-cy="cy-sign-up-email-step-title"
                    class="c-v-auth-step-header__title"
                    style="color:white"
                    v-if="showFillOtpPassword"
                  >
                  {{t(`Change`)}} {{t(`Password`)}}
                  </span>
                  
       
                <button
              data-cy="cy-sign-up-modal-close"
              style="color: white;width:40px;background-color:#2B2740;
               position:absolute;
                    right:10px;
                     top:10px;
              "
              class="c-v-signup-modal-close "
              @click="loginFunction(false)"
            >
              <font-awesome-icon :icon="['fas', 'x']" />
            </button>
                </header>
                  <!-- //login form -->
                  <div
                  v-if="!showResendEmail && !showFillOtpPassword"
                  class="bs-c-auth-container"
                  style="
                    margin-right: 20px;
                    height: 350px;
                    background-color: white;
                    border-radius: 20px;
                    padding: 10px;
                  "
                >
                 
                  <div class="bs-c-auth-content" style="background: white">
                    <div class="bs-c-auth-login">
                      <div>
                        <div
                          id="modal-login-form"
                          novalidate="novalidate"
                          class="bs-c-auth-login__form"
                        >
                          <div class="bs-c-auth-login-email">
                            <div class="bs-c-deposit__input-wrapper">
                              <input
                                style="padding: 12px; border-radius: 6px;margin-top:10px"
                                placeholder="Enter Your Username"
                                id="username"
                                v-model="loginUsername"
                                type="text"
                                name="username"
                                data-cy="cy-email-login-modal"
                                required="required"
                              />
                              <!-- <span class="form-input__error">The username is required.</span> -->
                            </div>
                          </div>
                          <div class="bs-c-auth-login-password">
                            <div class="bs-c-deposit__input-wrapper">
                              <div style="position: relative; width: 100%">
                                <input
                                  style="padding: 12px; border-radius: 6px"
                                  :type="passwordVisible ? 'text' : 'password'"
                                  id="password"
                                  v-model="loginPassword"
                                  placeholder="Enter your password"
                                />

                                <p
                                  type="button"
                                  @click="togglePasswordVisibility"
                                  style="
                                    position: absolute;
                                    right: 5px;
                                    top: 15%;
                                    transform: translateY(-50%);
                                    background-color: white;
                                    color: black;
                                    padding: 0px;
                                  "
                                >
                                  <!-- Icon for toggle button, you can use any icon you like -->
                                  <i
                                    style="color: black"
                                    :class="
                                      passwordVisible
                                        ? 'fas fa-eye-slash'
                                        : 'fas fa-eye'
                                    "
                                  ></i>
                                </p>
                              </div>
                              <!-- <span class="form-input__error" >The password field is required.</span> -->
                            </div>
                          </div>
                          <div class="bs-c-auth-login-cta">
                            <div class="bs-c-auth-login-button">
                              <button
                                @click="LoginSubmit"
                                style="margin-top: 10px; width: 100%"
                                class="auth-btn"
                              >
                                <div
                                  class="spinner"
                                  style="margin-top: 0px"
                                  v-if="loginLoader"
                                ></div>
                                <span v-else>Log In</span>
                              </button>
                              <div class="form-top-error-holder"></div>
                            </div>
                            <div
                              @click="ForgetPassword"
                              style="
                                cursor: pointer;
                                margin-top: 10px;
                                text-decoration: underline;
                              "
                              class="bs-c-auth-login-forgot-password"
                            >
                              {{t(`Forgot Password`)}}?
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bs-c-auth-footer">
                      <span class="bs-c-auth-footer__text">
                        {{t(`Don't have an account`)}}?
                      </span>
                      <a
                        @click="CloseLoginOpenSignup"
                        style="cursor: pointer; margin-top: 8px"
                        class="bs-c-auth-footer__link"
                      >
                        {{t(`Sign Up`)}}
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showResendEmail"
                  class="bs-c-auth-container"
                  style="
                    margin-right: 20px;
                    height: 350px;
                    background-color: white;
                    border-radius: 20px;
                    padding: 10px;
                  "
                >
                
                  <div
                    class="bs-c-auth-content"
                    style="background: white; margin-top: 40px"
                  >
                    <div class="bs-c-auth-login">
                      <div>
                        <div
                          id="modal-login-form"
                          novalidate="novalidate"
                          class="bs-c-auth-login__form"
                        >
                          <div class="bs-c-auth-login-email">
                            <div class="bs-c-deposit__input-wrapper">
                              <input
                                style="
                                  padding: 12px;
                                  border-radius: 6px;
                                  width: 100%;
                                  border: 1px solid gray;
                                "
                                placeholder="Enter your register email"
                                id="registerEmail"
                                v-model="registerEmail"
                                type="email"
                                name="registerEmail"
                                data-cy="cy-email-login-modal"
                                required="required"
                              />
                            </div>
                          </div>

                          <div class="bs-c-auth-login-cta">
                            <div class="bs-c-auth-login-button">
                              <button
                                @click="SendOtp"
                                style="width: 100%"
                                class="auth-btn"
                              >
                                <div
                                  class="spinner"
                                  style="margin-top: 0px"
                                  v-if="sendOtpLoader"
                                ></div>
                                <span v-else>{{t(`Send`)}} {{t(`Otp`)}}</span>
                              </button>
                              <div class="form-top-error-holder"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- vefiyr otp and chmage password -->
                <div
                  v-if="showFillOtpPassword"
                  class="bs-c-auth-container"
                  style="
                    margin-right: 20px;
                    height: 350px;
                    background-color: white;
                    border-radius: 20px;
                    padding: 10px;
                  "
                >
                 
                  <div
                    class="bs-c-auth-content"
                    style="background: white; margin-top: 40px"
                  >
                    <div class="bs-c-auth-login">
                      <div>
                        <div
                          id="modal-login-form"
                          novalidate="novalidate"
                          class="bs-c-auth-login__form"
                        >
                          <div class="bs-c-auth-login-email">
                            <div class="bs-c-deposit__input-wrapper">
                              <input
                                style="
                                  padding: 12px;
                                  border-radius: 6px;
                                  width: 100%;
                                  border: 1px solid gray;
                                "
                                placeholder="Enter your correct otp"
                                id="otp"
                                v-model="otp"
                                type="number"
                                name="otp"
                                data-cy="cy-email-login-modal"
                                required="required"
                              />
                            </div>
                          </div>
                          <div style="position: relative; width: 100%">
                            <input
                              style="padding: 12px; border-radius: 6px"
                              :type="passwordVisible ? 'text' : 'password'"
                              id="newpassword"
                              v-model="newPassword"
                              placeholder="Enter New password"
                            />

                            <p
                              type="button"
                              @click="togglePasswordVisibility"
                              style="
                                position: absolute;
                                right: 5px;
                                top: 15%;
                                transform: translateY(-50%);
                                background-color: white;
                                color: black;
                                padding: 0px;
                              "
                            >
                              <!-- Icon for toggle button, you can use any icon you like -->
                              <i
                                style="color: black"
                                :class="
                                  passwordVisible
                                    ? 'fas fa-eye-slash'
                                    : 'fas fa-eye'
                                "
                              ></i>
                            </p>
                          </div>

                          <div class="bs-c-auth-login-cta">
                            <div class="bs-c-auth-login-button">
                              <button
                                @click="UpdatePassword"
                                style="width: 100%"
                                class="auth-btn"
                              >
                                <div
                                  class="spinner"
                                  style="margin-top: 0px"
                                  v-if="verifyOtpLoader"
                                ></div>
                                <span v-else>{{t(`Update`)}} {{t(`Password`)}}</span>
                              </button>
                              <div class="form-top-error-holder"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </div>
        </div>

      </div>
    </div>

    <!-- signup form -->
    <div :style="{ display: signup }">
      <div
        aria-expanded="true"
        data-modal="sign-up-modal"
        class="c-v-signup-modal v--modal-overlay is-animation-initialized is-signup-flow"
        id="sign-up-modal"
        data-cy="cy-sign-up-modal"
      >

      <!-- signup-desktop -->
        <div class="v--modal-background-click signuphide">
          <div class="v--modal-top-right"></div>
          <div
            role="dialog"
            aria-modal="true"
            class="v--modal-box c-v-signup-modal-box"
          >
            <div class="signuphide"  >
              <img
                data-cy="cy-sign-up-modal-logo"
               :src="logoFeviconData?.site_logo"
                alt="BitStarz logo"
                class="c-v-signup-modal-logo signuphide"
              />
              <img
                src="https://d3oqh5ecy4r3n8.cloudfront.net/img/coin-1.3d1d9e5.png"
                alt="coin"
                class="c-v-signup-coin is-top-left signuphide"
              />
              <img
                src="https://d3oqh5ecy4r3n8.cloudfront.net/img/coin-2.b035f22.png"
                alt="coin"
                class="c-v-signup-coin is-top-right signuphide"
              />
              <img
                src="https://d3oqh5ecy4r3n8.cloudfront.net/img/coin-3.14e956a.png"
                alt="coin"
                class="c-v-signup-coin is-bottom-left signuphide"
              />
              <img
                src="https://d3oqh5ecy4r3n8.cloudfront.net/img/coin-4.7f83433.png"
                alt="coin"
                class="c-v-signup-coin is-bottom-right signuphide"
              />
            </div>

            <div
            
              data-cy="cy-welcome-info-dashboard signuphide"
              class="c-welcome-info-dashboard"
              
            >
              <div class="c-welcome-info-title">
                <span
                  data-cy="cy-welcome-info-title-text"
                  class="c-welcome-info-title__text"
                  >We pay your Winnings<br />with Lightning Speed ⚡️</span
                >
              </div>
              <div class="c-welcome-info-dashboard__content">
                <ul
                  data-cy="cy-welcome-info-dashboard-list"
                  class="c-welcome-info-dashboard-list"
                >
                  <li
                    data-cy="cy-welcome-info-dashboard-list-item--email"
                    class="c-welcome-info-dashboard-list__item is-active"
                  >
                    <div class="c-welcome-info-dashboard-list__item-content">
                      <div class="c-welcome-info-dashboard-list__item-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon sprite-icons"
                        >
                          <use
                            href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                            xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          ></use>
                        </svg>
                      </div>
                      <span class="c-welcome-info-dashboard-list__item-text">
                        {{t(`Start`)}} {{t(`with`)}} {{t(`email`)}}
                      </span>
                    </div>
                  </li>
                  <li
                    data-cy="cy-welcome-info-dashboard-list-item--set-logins"
                    class="c-welcome-info-dashboard-list__item"
                  >
                    <div class="c-welcome-info-dashboard-list__item-content">
                      <div class="c-welcome-info-dashboard-list__item-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon sprite-icons"
                        >
                          <use
                            href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                            xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-checkmark"
                          ></use>
                        </svg>
                      </div>
                      <span class="c-welcome-info-dashboard-list__item-text">
                        {{t(`set logins`)}}
                      </span>
                    </div>
                  </li>
                  <li
                    data-cy="cy-welcome-info-dashboard-list-item--final-step"
                    class="c-welcome-info-dashboard-list__item"
                  >
                    <div class="c-welcome-info-dashboard-list__item-content">
                      <div
                        class="c-welcome-info-dashboard-list__item-circle"
                      ></div>
                      <span class="c-welcome-info-dashboard-list__item-text">
                        {{t(`Welcome`)}} {{t(`Package`)}}:
                      </span>
                    </div>
                    <div class="c-welcome-package-bonus">
                      <span
                        data-cy="cy-welcome-package-bonus__item"
                        class="c-welcome-package-bonus__item"
                        >€500 or 5 BTC <br />
                        + 180 Free Spins</span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <button
                data-cy="cy-welcome-info-dashboard-bonus-code"
                class="c-welcome-info__bonus-code"
              >
               {{t(`Enter a bonus code`)}}
              </button>
            </div>

            <div
              v-if="!showOtherDetails"
              data-cy="cy-sign-up-step-wrapper "
              class="c-v-signup-step-wrapper signuphide"
            >
              <section data-cy="cy-sign-up-email-step" class="c-v-auth-step">
                <header class="c-v-auth-step-header">
                  <span
                    data-cy="cy-sign-up-email-step-title"
                    class="c-v-auth-step-header__title"
                  >
                    {{t(`Start`)}} {{t(`with`)}} {{t(`email`)}}
                  </span>
                </header>
                <div class="c-v-auth-step-container">
                  <div
                    v-if="!showOtherDetails"
                    class="c-v-auth-step-content is-dynamic-content"
                  >
                  <div  class="referral-container" >
                      <input
                      :style="{
    border: isVaildRefer === 1 ? '1px solid green' : isVaildRefer === 2 ? '1px solid red' : '1px solid gray'
  }"
                            data-cy="cy-sign-up-email-step-input"
                            maxlength="50"
                            autocomplete="nope"
                            autocapitalize="none"
                            placeholder="Enter referral  code"
                            class="refer-input"
                            type="text"
                            v-model="inputValue" 
                            @input="handleInput"
                          />
                          <button 
                          @click="handleApplyReferCode"
                          class="apply-btn">
                           <div class="spinnerApply" v-if="referLoading"></div> 
                           <span  v-else>{{ t(`Apply`) }}</span>

                          
                          </button>
                    <span >Have you referal code ?</span>
                          
                        </div>


                    <form
                      data-cy="cy-sign-up-email-step-form"
                      class="c-v-auth-step-form"
                      style="text-align: left"
                    >
                 


                      <div
                        data-v-df2841a0=""
                        class="c-v-input-label"
                        data-cy="cy-sign-up-email-step-input-label"
                      >
                        <span data-v-df2841a0="" class="c-v-input-label-text">
                          {{t(`Email`)}} {{t(`address`)}}
                        </span>
                      </div>
                      
                      <div class="c-v-auth-step-form-input-row">
                        
                        <div class="c-v-auth-step-input-box">
                          <input
                            data-cy="cy-sign-up-email-step-input"
                            maxlength="50"
                            autocomplete="nope"
                            autocapitalize="none"
                            placeholder="Enter your email"
                            class="c-v-auth-step-input"
                            style="font-size: 26px"
                            type="Email"
                            v-model="Email"
                            @input="validateRegisterEmail"
                          />
                        </div>
                        <span
                          data-cy="c-v-auth-step-input-hidden--email"
                          class="c-v-auth-step-input-hidden"
                          style="font-size: 26px"
                        >
                        </span>
                      </div>
                      <div class="c-input-separator-line">
                        <p v-if="emailCheck && Email" style="color: #ef4444">
                         {{t(`Doesn't appear to be a valid email. Wanna give it
                          another go`)}}?
                        </p>
                        <!-- <p v-if="!emailCheck && Email" style="color:#4ade80">Valid</p> -->
                      </div>
                      <span
                        v-if="!Email"
                        data-v-98742e1e=""
                        class="c-input-hint-text is-lower-case"
                        data-cy="cy-sign-up-email-step-input-hint"
                      >
                        Example: john.doe@gmail.com
                      </span>
                      <span
                        v-if="!Email"
                        data-cy="cy-sign-up-email-step-input-validation-text"
                        class="c-v-email-validation-text"
                      >
                        {{t(`The email field is required`)}}.
                      </span>
                      <button
                        @click.prevent="continueClicked"
                        style="margin-top: 50px"
                        class="auth-btn"
                      >
                        {{t(`Continue`)}}
                      </button>
                    </form>
                  </div>
                </div>
                <footer
                  data-cy="cy-sign-up-email-step-footer"
                  class="c-v-auth-step-footer"
                ></footer>
              </section>
            </div>

            <div
              v-if="showOtherDetails"
              data-cy="cy-sign-up-step-wrapper"
              class="c-v-signup-step-wrapper signuphide"
            >
              <section data-cy="cy-sign-up-email-step" class="c-v-auth-step">
                <header class="c-v-auth-step-header">
                  <span
                    data-cy="cy-sign-up-email-step-title"
                    class="c-v-auth-step-header__title"
                  >
                    {{t(`set Logins`)}}
                  </span>
                </header>
                <div>
                  <div v-if="showOtherDetails" style="padding: 20px">
                    <form @submit.prevent="submitForm">
                      <div class="box">
                        <label for="username">{{t(`Username`)}}:</label>
                        <input
                          type="text"
                          id="username"
                          v-model="username"
                          @input="validateUsername"
                          :style="{
                            border: invalidUsername
                              ? '1px solid red'
                              : '1px solid #ccc',
                          }"
                        />
                        <span
                          v-if="invalidUsername"
                          style="
                            color: red;
                            margin-top: -10px;
                            text-align: left;
                            font-size: 10px;
                          "
                          >{{ userNameErrorText }}</span
                        >
                      </div>
                      <div class="box" style=" width: 100%">
                        <label for="password">{{t(`Password`)}}:</label>
                        <div style="position: relative; width: 100%">
                          <input
                            :type="passwordVisible ? 'text' : 'password'"
                            id="password"
                            v-model="password"
                            @input="validatePassword"
                            :style="{
                              border: invalidPassword
                                ? '1px solid red'
                                : '1px solid #ccc',
                              width: '100%',
                            }"
                          />

                          <button
                            type="button"
                            @click="togglePasswordVisibility"
                            style="
                              position: absolute;
                              right: 5px;
                              top: 45%;
                              transform: translateY(-50%);
                              background-color: white;
                              color: black;
                              padding: 0px;
                            "
                          >
                            <!-- Icon for toggle button, you can use any icon you like -->
                            <i
                              style="color: black"
                              :class="
                                passwordVisible
                                  ? 'fas fa-eye-slash'
                                  : 'fas fa-eye'
                              "
                            ></i>
                          </button>
                        </div>
                        <span
                          v-if="invalidPassword"
                          style="
                            color: red;
                            margin-top: -10px;
                            text-align: left;
                            font-size: 10px;
                          "
                          >{{ passwordErrorText }}</span
                        >
                      </div>
                      <!-- Other form fields -->
                      <div class="box" style="margin-top: 10px">
                        <label for="currency">{{t(`Select`)}} {{t(`Currency`)}}:</label>
                        <select
                          id="currency"
                          v-model="currency"
                          @input="showCurrencyError"
                        >
                          <option value="">{{t(`Select`)}} {{t(`Currency`)}}</option>
                          <option
                            v-for="(option, index) in currencyOptions"
                            :key="index"
                            :value="option.value"
                          >
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                      <div class="box" style="margin-top: 10px">
                        <label>
                          <input type="checkbox" v-model="ageConfirmation" /> I
                          {{t(`confirm that I am 18 years or older`)}}
                        </label>
                        <span
                          v-if="!ageConfirmation && formSubmitted"
                          style="color: red"
                          >{{t(`Please confirm your age`)}}</span
                        >
                      </div>
                      <div class="box" style="margin-top: 5px">
                        <label>
                          <input type="checkbox" v-model="emailPreference" /> I
                          {{t(`would like to receive emails`)}}
                        </label>
                      </div>
                      <button
                        type="submit"
                        style="margin-top: 10px; width: 100%"
                        class="auth-btn"
                      >
                        <div
                          class="spinner"
                          style="margin-top: 0px"
                          v-if="registerLoader"
                        ></div>
                        <span v-else>{{t(`Submit`)}}</span>
                      </button>
                    </form>
                  </div>
                </div>
                <footer
                  data-cy="cy-sign-up-email-step-footer"
                  class="c-v-auth-step-footer"
                ></footer>
              </section>
            </div>

            <button
              data-cy="cy-sign-up-modal-close"
              style="color: black"
              class="c-v-signup-modal-close signuphide"
              @click="signUpFunction(false)"
            >
              <font-awesome-icon :icon="['fas', 'x']" />
            </button>
          </div>

         
        </div>
<!-- signup mobile -->
        <div class="signupMobile" >


            <div
              v-if="!showOtherDetails"
              data-cy="cy-sign-up-step-wrapper "
              class="c-v-signup-step-wrapper "
              style="width:100%;border-radius:0px"
            >
              <section data-cy="cy-sign-up-email-step" class="c-v-auth-step">
                <header class="c-v-auth-step-header">
                  <span
                    data-cy="cy-sign-up-email-step-title"
                    class="c-v-auth-step-header__title"
                  >
                    {{t(`Start`)}} {{t(`with`)}} {{t(`email`)}}
                  </span>
                  
       
                <button
              data-cy="cy-sign-up-modal-close"
              style="color: black;width:40px;
               position:absolute;
                    right:10px;
                     top:10px;
              "
              class="c-v-signup-modal-close "
              @click="signUpFunction(false)"
            >
              <font-awesome-icon :icon="['fas', 'x']" />
            </button>
                </header>
                <div class="c-v-auth-step-container">
                  
                  <div
                    v-if="!showOtherDetails"
                    class="c-v-auth-step-content is-dynamic-content"
                  >
                  <div  class="referral-container" >
                      <input
                      :style="{
    border: isVaildRefer === 1 ? '1px solid green' : isVaildRefer === 2 ? '1px solid red' : '1px solid gray'
  }"
                            data-cy="cy-sign-up-email-step-input"
                            maxlength="50"
                            autocomplete="nope"
                            autocapitalize="none"
                            placeholder="Enter referral  code"
                            class="refer-input"
                            type="text"
                            v-model="inputValue" 
                            @input="handleInput"
                          />
                          <button 
                          @click="handleApplyReferCode"
                          class="apply-btn">
                           <div class="spinnerApply" v-if="referLoading"></div> 
                           <span  v-else>{{ t(`Apply`) }}</span>

                          
                          </button>
                    <span >Have you referal code ?</span>
                          
                        </div>
                    <form
                      data-cy="cy-sign-up-email-step-form"
                      class="c-v-auth-step-form"
                      style="text-align: left"
                    >
                      <div
                        data-v-df2841a0=""
                        class="c-v-input-label"
                        data-cy="cy-sign-up-email-step-input-label"
                      >
                        <span data-v-df2841a0="" class="c-v-input-label-text">
                          {{t(`Email`)}} {{t(`address`)}}
                        </span>
                      </div>
                      
                      <div class="c-v-auth-step-form-input-row">
                        <div class="c-v-auth-step-input-box">
                          <input
                            data-cy="cy-sign-up-email-step-input"
                            maxlength="50"
                            autocomplete="nope"
                            autocapitalize="none"
                            placeholder="Enter your email"
                            class="c-v-auth-step-input"
                            style="font-size: 26px"
                            type="Email"
                            v-model="Email"
                            @input="validateRegisterEmail"
                          />
                        </div>
                        <span
                          data-cy="c-v-auth-step-input-hidden--email"
                          class="c-v-auth-step-input-hidden"
                          style="font-size: 26px"
                        >
                        </span>
                      </div>
                      <div class="c-input-separator-line">
                        <p v-if="emailCheck && Email" style="color: #ef4444">
                          {{t(`Doesn't appear to be a valid email. Wanna give it
                          another go`)}}
                        </p>
                        <!-- <p v-if="!emailCheck && Email" style="color:#4ade80">Valid</p> -->
                      </div>
                      <span
                        v-if="!Email"
                        data-v-98742e1e=""
                        class="c-input-hint-text is-lower-case"
                        data-cy="cy-sign-up-email-step-input-hint"
                      >
                        Example: john.doe@gmail.com
                      </span>
                      <span
                        v-if="!Email"
                        data-cy="cy-sign-up-email-step-input-validation-text"
                        class="c-v-email-validation-text"
                      >
                        {{t(`The email field is required`)}}.
                      </span>
                      <button
                        @click.prevent="continueClicked"
                        style="margin-top: 50px"
                        class="auth-btn"
                      >
                        {{t(`Continue`)}}
                      </button>
                    </form>
                  </div>
                </div>
                <footer
                  data-cy="cy-sign-up-email-step-footer"
                  class="c-v-auth-step-footer"
                ></footer>
              </section>
            </div>

            <div
              v-if="showOtherDetails"
              data-cy="cy-sign-up-step-wrapper"
              class="c-v-signup-step-wrapper "
              style="width:100%;border-radius:0px"
            >
              <section data-cy="cy-sign-up-email-step" class="c-v-auth-step">
                <header class="c-v-auth-step-header">
                  <span
                    data-cy="cy-sign-up-email-step-title"
                    class="c-v-auth-step-header__title"
                  >
                    {{t(`set Logins`)}}
                  </span>

                  <button
              data-cy="cy-sign-up-modal-close"
              style="color: black;width:40px;
               position:absolute;
                    right:10px;
                     top:10px;
              "
              class="c-v-signup-modal-close "
              @click="signUpFunction(false)"
            >
              <font-awesome-icon :icon="['fas', 'x']" />
            </button>
                </header>
                <div>
                  <div v-if="showOtherDetails" style="padding: 20px">
                    <form @submit.prevent="submitForm">
                      <div class="box">
                        <label for="username">{{t(`Username`)}}:</label>
                        <input
                          type="text"
                          id="username"
                          v-model="username"
                          @input="validateUsername"
                          :style="{
                            border: invalidUsername
                              ? '1px solid red'
                              : '1px solid #ccc',
                          }"
                        />
                        <span
                          v-if="invalidUsername"
                          style="
                            color: red;
                            margin-top: -10px;
                            text-align: left;
                            font-size: 10px;
                          "
                          >{{ userNameErrorText }}</span
                        >
                      </div>
                      <div class="box" style=" width: 100%">
                        <label for="password">Password:</label>
                        <div style="position: relative; width: 100%">
                          <input
                            :type="passwordVisible ? 'text' : 'password'"
                            id="password"
                            v-model="password"
                            @input="validatePassword"
                            :style="{
                              border: invalidPassword
                                ? '1px solid red'
                                : '1px solid #ccc',
                              width: '100%',
                            }"
                          />

                          <button
                            type="button"
                            @click="togglePasswordVisibility"
                            style="
                              position: absolute;
                              right: 5px;
                              top: 45%;
                              transform: translateY(-50%);
                              background-color: white;
                              color: black;
                              padding: 0px;
                            "
                          >
                            <!-- Icon for toggle button, you can use any icon you like -->
                            <i
                              style="color: black"
                              :class="
                                passwordVisible
                                  ? 'fas fa-eye-slash'
                                  : 'fas fa-eye'
                              "
                            ></i>
                          </button>
                        </div>
                        <span
                          v-if="invalidPassword"
                          style="
                            color: red;
                            margin-top: -10px;
                            text-align: left;
                            font-size: 10px;
                          "
                          >{{ passwordErrorText }}</span
                        >
                      </div>
                      <!-- Other form fields -->
                      <div class="box" style="margin-top: 10px">
                        <label for="currency">{{t(`Select`)}} {{t(`Currency`)}}:</label>
                        <select
                          id="currency"
                          v-model="currency"
                          @input="showCurrencyError"
                        >
                          <option value="">{{(`Select`)}} {{t(`Currency`)}}</option>
                          <option
                            v-for="(option, index) in currencyOptions"
                            :key="index"
                            :value="option.value"
                          >
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                      <div class="box" style="margin-top: 10px">
                        <label>
                          <input type="checkbox" v-model="ageConfirmation" /> I
                          {{t(`confirm that I am 18 years or older`)}}
                        </label>
                        <span
                          v-if="!ageConfirmation && formSubmitted"
                          style="color: red"
                          >{{t(`Please confirm your age`)}}</span
                        >
                      </div>
                      <div class="box" style="margin-top: 5px">
                        <label>
                          <input type="checkbox" v-model="emailPreference" /> I
                          {{t(`would like to receive emails`)}}
                        </label>
                      </div>
                      <button
                        type="submit"
                        style="margin-top: 10px; width: 100%"
                        class="auth-btn"
                      >
                        <div
                          class="spinner"
                          style="margin-top: 0px"
                          v-if="registerLoader"
                        ></div>
                        <span v-else>{{t(`Submit`)}}</span>
                      </button>
                    </form>
                  </div>
                </div>
                <footer
                  data-cy="cy-sign-up-email-step-footer"
                  class="c-v-auth-step-footer"
                ></footer>
              </section>
            </div>

           
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { inject } from "vue";
import providerData from "../script/json/casinoprovider.json";
import SearchModal from "../components/modal/SearchModal.vue";
import Verfication from './Verfication.vue'
import Search from '../components/Search.vue'
import Promotions from "@/components/Promotions.vue";
import DepositModal from "@/components/modal/DepositModal.vue";
import WithDrawalModal from "@/components/modal/WithDrawalModal.vue";
import UserWallet from "../components/user/UserWallet.vue";
import { formatDateTime , decodeData, encodeData} from "@/utils/utils";
export default {
    components: {
    SearchModal,
    Search,
    Verfication,
    Promotions,
    DepositModal,
    WithDrawalModal,
    UserWallet
  
  },
  name: "Header",
  setup() {
    const modalState = inject("modalState");
    const closeModal1 = inject("closeModal1");
    const openModal1 = inject("openModal1");
    const closeModal2 = inject("closeModal2");
    const openModal2 = inject("openModal2");
    const closeModal3 = inject("closeModal3");
    const openModal3 = inject("openModal3");

    return {
      modalState,
      closeModal1,
      openModal1,
      closeModal2,
      openModal2,
      closeModal3,
      openModal3,
    };
  },
  data() {
    return {
      // Initialize with the sidebar closed or at a certain width
      sidebarWidth: "0px",
      padding: "0px",
      gameCategory:[],
      logoFeviconData:{},
      displayTopHeaderMobile: "none",
      openedIndex: null,
      profile: "none",
      login: "none",
      showSearchModal:false,
      signup: "none",
      dashboard: "none",
      history: "none",
      myrewards: "none",
      myprofile: "none",
      isActive: 100,
      registerEmail: "",
      ageConfirmation: false,
      SITEAUTHKEY: "",
      isAuthicate: true,
      tooltips: {
        bell: false,
        admin: false,
      },
      Promotions: [], // Corrected variable name
      loading: true,
      isModalOpen: false,
      isLogged: false,
      formSubmitted: false,
      Email: "",
      showResendEmail: false,
      showFillOtpPassword: false,
      username: "",
      password: "",
      currency: "",
      selectedCountry: "",
      filteredCurrencyOptions: [],
      loginUsername: "",
      loginPassword: "",
      isLogged: false,
      activeTab: "Transactions",
      userData: {},
      data: [],
      widrowData: [],
      betData: [],
      userBonus: [],
      checkData: [],
      isLoading: false,
      category: "",
      sub_category: "",
      status: "all",
      limit: 10,
      currentPage: 1,
      pagination: {},
      country: "",
      invalidUsername: false,
      invalidPassword: false,
      passwordVisible: false,
      transactions: [],
      showDropdown: false,
      searchQuery: '',
      items: [
        // Example items, replace with your actual data
        { id: 1, name: 'Roulette' },
        { id: 2, name: 'Book' },
        { id: 3, name: 'Game Studio' },
      ],
      otp: "",
      newPassword: "",
      currencyOption: "",
      emailCheck: false,
      showOtherDetails: false,
      showPassword: false,
      registerLoader: false,
      loginLoader: false,
      sendOtpLoader: false,
      verifyOtpLoader: false,
      inputAmount: 0,
      selectLangauge: "",
      amount: "",
      depositLoader: false,
      imgLoader: false,
      selectedGateway: null,
      userInputs: [],
      selectedFiles: [],
      WithDrawLoader:false,
      step: 1,
      userNameErrorText:
        "Username should be contain upper&lower/case & numerica value",
      passwordErrorText:
        "password sholud be 9 chracter with uppercase/lowercase and number",
      currencyOptions: [
        {
          value: "ALL",
          label: "Albanian Lek (ALL)",
          countriesUsedCurrency: ["Albania"],
        },
        {
          value: "ADP",
          label: "Andorran Peseta (ADP)",
          countriesUsedCurrency: ["Andorra"],
        },
        {
          value: "AOA",
          label: "Angolan Kwanza (AOA)",
          countriesUsedCurrency: ["Angola"],
        },
        {
          value: "ARS",
          label: "Argentine Peso (ARS)",
          countriesUsedCurrency: ["Argentina"],
        },
        {
          value: "XCD",
          label: "East Caribbean Dollar (XCD)",
          countriesUsedCurrency: [
            "Antigua  Deis",
            "Dominica",
            "Grenada",
            "St Kitts & Nevis",
            "St Lucia",
          ],
        },

        {
          value: "AMD",
          label: "Armenian Dram (AMD)",
          countriesUsedCurrency: ["Armenia"],
        },
        {
          value: "AED",
          label: "United Arab Emirates Dirham (AED)",
          countriesUsedCurrency: ["United Arab Emirates"],
        },
        {
          value: "AFN",
          label: "Afghanistan (AFN)",
          countriesUsedCurrency: ["Afghanistan"],
        },

        {
          value: "AUD",
          label: "Australian Dollar (AUD)",
          countriesUsedCurrency: ["Australia", "Nauru", "Kiribati", "Tuvalu"],
        },

        {
          value: "AZN",
          label: "Azerbaijani Manat (AZN)",
          countriesUsedCurrency: ["Azerbaijan"],
        },
        {
          value: "BSD",
          label: "Bahamian Dollar (BSD)",
          countriesUsedCurrency: ["Bahamas"],
        },
        {
          value: "BHD",
          label: "Bahraini Dinar (BHD)",
          countriesUsedCurrency: ["Bahrain"],
        },
        {
          value: "BBD",
          label: "Barbadian Dollar (BBD)",
          countriesUsedCurrency: ["Barbados"],
        },
        {
          value: "BYN",
          label: "Belarusian Ruble (BYN)",
          countriesUsedCurrency: ["Belarus"],
        },
        {
          value: "BDT",
          label: "Bangladeshi Taka (BDT)",
          countriesUsedCurrency: ["Bangladesh"],
        },
        {
          value: "BND",
          label: "Brunei Dollar (BND)",
          countriesUsedCurrency: ["Brunei"],
        },

        {
          value: "BZD",
          label: "Belize Dollar (BZD)",
          countriesUsedCurrency: ["Belize"],
        },

        {
          value: "BMD",
          label: "Bermudian Dollar (BMD)",
          countriesUsedCurrency: ["Bermuda"],
        },
        {
          value: "INR",
          label: "Indian Rupee (INR)",
          countriesUsedCurrency: ["Bhutan"],
        },
        {
          value: "BOB",
          label: "Bolivian Boliviano (BOB)",
          countriesUsedCurrency: ["Bolivia"],
        },
        {
          value: "BAM",
          label: "Bosnia-Herzegovina Convertible Mark (BAM)",
          countriesUsedCurrency: ["Bosnia Herzegovina"],
        },
        {
          value: "BWP",
          label: "Botswana Pula (BWP)",
          countriesUsedCurrency: ["Botswana"],
        },
        {
          value: "BRL",
          label: "Brazilian Real (BRL)",
          countriesUsedCurrency: ["Brazil"],
        },

        {
          value: "BGN",
          label: "Bulgarian Lev (BGN)",
          countriesUsedCurrency: ["Bulgaria"],
        },
        {
          value: "XOF",
          label: "West African CFA Franc (XOF)",
          countriesUsedCurrency: [
            "Burkina",
            "Benin",
            "Guinea-Bissau",
            "Ivory Coast",
            "Mali",
            "Niger",
            "Senegal",
            "Togo",
          ],
        },
        {
          value: "BIF",
          label: "Burundian Franc (BIF)",
          countriesUsedCurrency: ["Burundi"],
        },
        {
          value: "XAF",
          label: "Central African CFA Franc (XAF)",
          countriesUsedCurrency: [
            "Cameroon",
            "Central African Republic",
            "Chad",
            "Congo",
            "Equatorial Guinea",
            "Gabon",
          ],
        },
        {
          value: "CVE",
          label: "Cape Verdean Escudo (CVE)",
          countriesUsedCurrency: ["Cape Verde"],
        },
        {
          value: "KYD",
          label: "Cayman Islands Dollar (KYD)",
          countriesUsedCurrency: ["Cayman Islands"],
        },

        {
          value: "CLP",
          label: "Chilean Peso (CLP)",
          countriesUsedCurrency: ["Chile"],
        },
        {
          value: "CNY",
          label: "Chinese Yuan (CNY)",
          countriesUsedCurrency: ["China"],
        },
        {
          value: "COP",
          label: "Colombian Peso (COP)",
          countriesUsedCurrency: ["Colombia"],
        },
        {
          value: "KMF",
          label: "Comorian Franc (KMF)",
          countriesUsedCurrency: ["Comoros"],
        },
        {
          value: "CDF",
          label: "Congolese Franc (CDF)",
          countriesUsedCurrency: ["Congo (Democratic Rep)"],
        },
        {
          value: "CRC",
          label: "Costa Rican Colón (CRC)",
          countriesUsedCurrency: ["Costa Rica"],
        },
        {
          value: "HRK",
          label: "Croatian Kuna (HRK)",
          countriesUsedCurrency: ["Croatia"],
        },
        {
          value: "CUP",
          label: "Cuban Peso (CUP)",
          countriesUsedCurrency: ["Cuba"],
        },
        {
          value: "CYP",
          label: "Cypriot Pound (CYP)",
          countriesUsedCurrency: ["Cyprus"],
        },
        {
          value: "CAD",
          label: "Canadian Dollar (CAD)",
          countriesUsedCurrency: ["Canada"],
        },

        {
          value: "CZK",
          label: "Czech Koruna (CZK)",
          countriesUsedCurrency: ["Czech Republic"],
        },
        {
          value: "DKK",
          label: "Danish Krone (DKK)",
          countriesUsedCurrency: ["Denmark"],
        },
        {
          value: "DOP",
          label: "Dominican Peso (DOP)",
          countriesUsedCurrency: ["Dominican Republic"],
        },
        {
          value: "DJF",
          label: "Djiboutian Franc (DJF)",
          countriesUsedCurrency: ["Djibouti"],
        },

        {
          value: "DZD",
          label: "Algerian Dinar (DZD)",
          countriesUsedCurrency: ["Algeria"],
        },
        {
          value: "EUR",
          label: "Germany (EUR)",
          countriesUsedCurrency: [
            "Austria",
            "Belgium",
            "Cyprus",
            "Estonia",
            "Monaco",
            "Montenegro",
            "Finland",
            "France",
            "Germany",
            "Greece",
            "Ireland",
            "Italy",
            "Kosovo",
            "Latvia",
            "San Marino",
            "Lithuania",
            "Vatican City",
            "Luxembourg",
            "Malta",
            "Netherlands",
            "Portugal",
            "Slovakia",
            "Slovenia",
            "Spain",
            "Ireland (Republic)",
          ],
        },
        {
          value: "LBP",
          label: "Lebanese Pound (LBP)",
          countriesUsedCurrency: ["Lebanon"],
        },
        {
          value: "LSL",
          label: "Lesotho Loti (LSL)",
          countriesUsedCurrency: ["Lesotha"],
        },
        {
          value: "LRD",
          label: "Liberian Dollar (LRD)",
          countriesUsedCurrency: ["Liberia"],
        },
        {
          value: "LYD",
          label: "Libyan Dinar (LYD)",
          countriesUsedCurrency: ["Libya"],
        },

        {
          value: "CHF",
          label: "Swiss Franc (CHF)",
          countriesUsedCurrency: ["Liechtenstein", "Switzerland"],
        },
        {
          value: "MKD",
          label: "Macedonian Denar (MKD)",
          countriesUsedCurrency: ["Macedonia"],
        },
        {
          value: "MGA",
          label: "Malagasy Ariary (MGA)",
          countriesUsedCurrency: ["Madagascar"],
        },
        {
          value: "MWK",
          label: "Malawian Kwacha (MWK)",
          countriesUsedCurrency: ["Malawi"],
        },
        {
          value: "MYR",
          label: "Malaysian Ringgit (MYR)",
          countriesUsedCurrency: ["Malaysia"],
        },
        {
          value: "MRO",
          label: "Mauritanian Ouguiya (MRO)",
          countriesUsedCurrency: ["Mauritania"],
        },
        {
          value: "MUR",
          label: "Mauritian Rupee (MUR)",
          countriesUsedCurrency: ["Mauritius"],
        },
        {
          value: "MZN",
          label: "Mozambican Metical (MZN)",
          countriesUsedCurrency: ["Mozambique"],
        },
        {
          value: "MMK",
          label: "Myanmar Kyat (MMK)",
          countriesUsedCurrency: ["Myanmar (Burma)", "Myanmar"],
        },
        {
          value: "NAD",
          label: "Namibian Dollar (NAD)",
          countriesUsedCurrency: ["Namibia"],
        },

        {
          value: "NIO",
          label: "Nicaraguan Córdoba (NIO)",
          countriesUsedCurrency: ["Nicaragua"],
        },
        {
          value: "OMR",
          label: "Omani Rial (OMR)",
          countriesUsedCurrency: ["Oman"],
        },
        {
          value: "ERN",
          label: "Eritrean Nakfa (ERN)",
          countriesUsedCurrency: ["Eritrea"],
        },
        {
          value: "ETB",
          label: "Ethiopian Birr (ETB)",
          countriesUsedCurrency: ["Ethiopia"],
        },
        {
          value: "FJD",
          label: "Fijian Dollar (FJD)",
          countriesUsedCurrency: ["Fiji"],
        },
        {
          value: "GMD",
          label: "Gambian Dalasi (GMD)",
          countriesUsedCurrency: ["Gambia"],
        },
        {
          value: "GEL",
          label: "Georgian Lari (GEL)",
          countriesUsedCurrency: ["Georgia"],
        },
        {
          value: "GHS",
          label: "Ghanaian Cedi (GHS)",
          countriesUsedCurrency: ["Ghana"],
        },
        {
          value: "GTQ",
          label: "Guatemalan Quetzal (GTQ)",
          countriesUsedCurrency: ["Guatemala"],
        },
        {
          value: "GNF",
          label: "Guinean Franc (GNF)",
          countriesUsedCurrency: ["Guinea"],
        },
        {
          value: "GYD",
          label: "Guyanese Dollar (GYD)",
          countriesUsedCurrency: ["Guyana"],
        },
        {
          value: "HTG",
          label: "Haitian Gourde (HTG)",
          countriesUsedCurrency: ["Haiti"],
        },
        {
          value: "HNL",
          label: "Honduran Lempira (HNL)",
          countriesUsedCurrency: ["Honduras"],
        },
        {
          value: "HUF",
          label: "Hungarian Forint (HUF)",
          countriesUsedCurrency: ["Hungary"],
        },
        {
          value: "ISK",
          label: "Icelandic Króna (ISK)",
          countriesUsedCurrency: ["Iceland"],
        },
        {
          value: "IRR",
          label: "Iranian Rial (IRR)",
          countriesUsedCurrency: ["Iran"],
        },
        {
          value: "IQD",
          label: "Iraqi Dinar (IQD)",
          countriesUsedCurrency: ["Iraq"],
        },
        {
          value: "GBP",
          label: "British Pound (GBP)",
          countriesUsedCurrency: ["United Kingdom"],
        },
        {
          value: "HKD",
          label: "Hong Kong Dollar (HKD)",
          countriesUsedCurrency: ["Hong Kong"],
        },

        {
          value: "IDR",
          label: "Indonesian Rupiah (IDR)",
          countriesUsedCurrency: ["Indonesia"],
        },
        {
          value: "IDO",
          label: "Indian Rupee (IDO)",
          countriesUsedCurrency: ["India"],
        },
        {
          value: "INR",
          label: "Indian Rupee (INR)",
          countriesUsedCurrency: ["India"],
        },
        {
          value: "JPY",
          label: "Japanese Yen (JPY)",
          countriesUsedCurrency: ["Japan"],
        },
        {
          value: "KRW",
          label: "South Korean Won (KRW)",
          countriesUsedCurrency: ["South Korea", "Korea South"],
        },

        {
          value: "KHR",
          label: "Cambodian Riel (KHR)",
          countriesUsedCurrency: ["Cambodia"],
        },
        {
          value: "LAK",
          label: "Laotian Kip (LAK)",
          countriesUsedCurrency: ["Laos"],
        },
        {
          value: "LKR",
          label: "Sri Lankan Rupee (LKR)",
          countriesUsedCurrency: ["Sri Lanka"],
        },
        {
          value: "ILS",
          label: "Israeli Shekel (ILS)",
          countriesUsedCurrency: ["Israel"],
        },
        {
          value: "JMD",
          label: "Jamaican Dollar (JMD)",
          countriesUsedCurrency: ["Jamaica"],
        },
        {
          value: "JOD",
          label: "Jordanian Dinar (JOD)",
          countriesUsedCurrency: ["Jordan"],
        },
        {
          value: "KZT",
          label: "Kazakhstani Tenge (KZT)",
          countriesUsedCurrency: ["Kazakhstan"],
        },
        {
          value: "KES",
          label: "Kenyan Shilling (KES)",
          countriesUsedCurrency: ["Kenya"],
        },

        {
          value: "KPW",
          label: "North Korean Won (KPW)",
          countriesUsedCurrency: ["Korea North"],
        },

        {
          value: "KWD",
          label: "Kuwaiti Dinar (KWD)",
          countriesUsedCurrency: ["Kuwait"],
        },
        {
          value: "KGS",
          label: "Kyrgyzstani Som (KGS)",
          countriesUsedCurrency: ["Kyrgyzstan"],
        },
        {
          value: "MAD",
          label: "Moroccan Dirham (MAD)",
          countriesUsedCurrency: ["Morocco"],
        },

        {
          value: "MNT",
          label: "Mongolian Tugrik (MNT)",
          countriesUsedCurrency: ["Mongolia"],
        },
        {
          value: "MYK",
          label: "Malaysian Ringgit (MYK)",
          countriesUsedCurrency: ["Malaysia"],
        },
        {
          value: "MXN",
          label: "Mexican Peso (MXN)",
          countriesUsedCurrency: ["Mexico"],
        },

        {
          value: "NGN",
          label: "Nigerian Naira (NGN)",
          countriesUsedCurrency: ["Nigeria"],
        },
        {
          value: "NOK",
          label: "Norwegian Krone (NOK)",
          countriesUsedCurrency: ["Norway"],
        },
        {
          value: "NPR",
          label: "Nepalese Rupee (NPR)",
          countriesUsedCurrency: ["Nepal"],
        },
        {
          value: "NZD",
          label: "New Zealand Dollar (NZD)",
          countriesUsedCurrency: ["New Zealand"],
        },
        {
          value: "PEN",
          label: "Peruvian Nuevo Sol (PEN)",
          countriesUsedCurrency: ["Peru"],
        },
        {
          value: "PKR",
          label: "Pakistani Rupee (PKR)",
          countriesUsedCurrency: ["Pakistan"],
        },
        {
          value: "RUB",
          label: "Russian Ruble (RUB)",
          countriesUsedCurrency: ["Russian Federation"],
        },
        {
          value: "SEK",
          label: "Swedish Krona (SEK)",
          countriesUsedCurrency: ["Sweden"],
        },
        {
          value: "THB",
          label: "Thai Baht (THB)",
          countriesUsedCurrency: ["Thailand"],
        },
        {
          value: "TRY",
          label: "Turkish Lira (TRY)",
          countriesUsedCurrency: ["Turkey"],
        },
        {
          value: "UCC",
          label: "Ukrainian Hryvnia (UCC)",
          countriesUsedCurrency: ["Ukraine"],
        },
        {
          value: "USD",
          label: "Germany,United States Dollar (USD)",
          countriesUsedCurrency: [
            "Germany",
            "Panama",
            "United States",
            "East Timor",
            "Ecuador",
            "El Salvador",
            "Guatemala",
            "Haiti",
            "Honduras",
            "Jamaica",
            "Jordan",
            "Marshall Islands",
            "Micronesia",
            "Palau",
          ],
        },
        {
          value: "VES",
          label: "Venezuelan Bolívar (VES)",
          countriesUsedCurrency: ["Venezuela"],
        },
        {
          value: "PGK",
          label: "Papua New Guinean Kina (PGK)",
          countriesUsedCurrency: ["Papua New Guinea"],
        },
        {
          value: "PYG",
          label: "Paraguayan Guarani (PYG)",
          countriesUsedCurrency: ["Paraguay"],
        },
        {
          value: "PHP",
          label: "Philippine Peso (PHP)",
          countriesUsedCurrency: ["Philippines"],
        },
        {
          value: "PLN",
          label: "Polish Złoty (PLN)",
          countriesUsedCurrency: ["Poland"],
        },
        {
          value: "QAR",
          label: "Qatari Riyal (QAR)",
          countriesUsedCurrency: ["Qatar"],
        },
        {
          value: "RON",
          label: "Romanian Leu (RON)",
          countriesUsedCurrency: ["Romania"],
        },
        {
          value: "RWF",
          label: "Rwandan Franc (RWF)",
          countriesUsedCurrency: ["Rwanda"],
        },

        {
          value: "WST",
          label: "Samoan Tala (WST)",
          countriesUsedCurrency: ["Samoa"],
        },

        {
          value: "STN",
          label: "São Tomé & Príncipe Dobra (STN)",
          countriesUsedCurrency: ["Sao Tome & Principe"],
        },
        {
          value: "SAR",
          label: "Saudi Riyal (SAR)",
          countriesUsedCurrency: ["Saudi Arabia"],
        },
        {
          value: "RSD",
          label: "Serbian Dinar (RSD)",
          countriesUsedCurrency: ["Serbia"],
        },
        {
          value: "SCR",
          label: "Seychellois Rupee (SCR)",
          countriesUsedCurrency: ["Seychelles"],
        },
        {
          value: "SLL",
          label: "Sierra Leonean Leone (SLL)",
          countriesUsedCurrency: ["Sierra Leone"],
        },
        {
          value: "VND",
          label: "Vietnamese Dong (VND)",
          countriesUsedCurrency: ["Vietnam"],
        },
        {
          value: "VNO",
          label: "Vanuatu Vatu (VNO)",
          countriesUsedCurrency: ["Vanuatu"],
        },
        {
          value: "SGD",
          label: "Singapore Dollar (SGD)",
          countriesUsedCurrency: ["Singapore"],
        },
        {
          value: "SBD",
          label: "Solomon Islands Dollar (SBD)",
          countriesUsedCurrency: ["Solomon Islands"],
        },
        {
          value: "SOS",
          label: "Somali Shilling (SOS)",
          countriesUsedCurrency: ["Somalia"],
        },
        {
          value: "SSP",
          label: "South Sudanese Pound (SSP)",
          countriesUsedCurrency: ["South Sudan"],
        },
        {
          value: "SDG",
          label: "Sudanese Pound (SDG)",
          countriesUsedCurrency: ["Sudan"],
        },
        {
          value: "SRD",
          label: "Surinamese Dollar (SRD)",
          countriesUsedCurrency: ["Suriname"],
        },
        {
          value: "SZL",
          label: "Swazi Lilangeni (SZL)",
          countriesUsedCurrency: ["Swaziland"],
        },
        {
          value: "SYP",
          label: "Syrian Pound (SYP)",
          countriesUsedCurrency: ["Syria"],
        },
        {
          value: "TWD",
          label: "New Taiwan Dollar (TWD)",
          countriesUsedCurrency: ["Taiwan"],
        },
        {
          value: "TJS",
          label: "Tajikistani Somoni (TJS)",
          countriesUsedCurrency: ["Tajikistan"],
        },
        {
          value: "TZS",
          label: "Tanzanian Shilling (TZS)",
          countriesUsedCurrency: ["Tanzania"],
        },
        {
          value: "TOP",
          label: "Tongan Pa'anga (TOP)",
          countriesUsedCurrency: ["Tonga"],
        },
        {
          value: "TTD",
          label: "Trinidad and Tobago Dollar (TTD)",
          countriesUsedCurrency: ["Trinidad & Tobago"],
        },
        {
          value: "TND",
          label: "Tunisian Dinar (TND)",
          countriesUsedCurrency: ["Tunisia"],
        },
        {
          value: "TMT",
          label: "Turkmenistani Manat (TMT)",
          countriesUsedCurrency: ["Turkmenistan"],
        },

        {
          value: "UGX",
          label: "Ugandan Shilling (UGX)",
          countriesUsedCurrency: ["Uganda"],
        },
        {
          value: "UYU",
          label: "Uruguayan Peso (UYU)",
          countriesUsedCurrency: ["Uruguay"],
        },
        {
          value: "UZS",
          label: "Uzbekistani Som (UZS)",
          countriesUsedCurrency: ["Uzbekistan"],
        },

        {
          value: "YER",
          label: "Yemeni Rial (YER)",
          countriesUsedCurrency: ["Yemen"],
        },
        {
          value: "ZMW",
          label: "Zambian Kwacha (ZMW)",
          countriesUsedCurrency: ["Zambia"],
        },
        {
          value: "ZWL",
          label: "Zimbabwean Dollar (ZWL)",
          countriesUsedCurrency: ["Zimbabwe"],
        },
        {
          value: "ZAR",
          label: "South African Rand (ZAR)",
          countriesUsedCurrency: ["South Africa"],
        },
      ],
      secondaryBets:[],
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua & Deps",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Central African Rep",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo {Democratic Rep}",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland {Republic}",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea North",
        "Korea South",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar, {Burma}",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "St Kitts & Nevis",
        "St Lucia",
        "Saint Vincent & the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome & Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      countryPhoneCodes: {
        Afghanistan: "+93",
        Albania: "+355",
        Algeria: "+213",
        Andorra: "+376",
        Angola: "+244",
        "Antigua & Deps": "+1 268",
        Argentina: "+54",
        Armenia: "+374",
        Australia: "+61",
        Austria: "+43",
        Azerbaijan: "+994",
        Bahamas: "+1 242",
        Bahrain: "+973",
        Bangladesh: "+880",
        Barbados: "+1 246",
        Belarus: "+375",
        Belgium: "+32",
        Belize: "+501",
        Benin: "+229",
        Bhutan: "+975",
        Bolivia: "+591",
        "Bosnia Herzegovina": "+387",
        Botswana: "+267",
        Brazil: "+55",
        Brunei: "+673",
        Bulgaria: "+359",
        Burkina: "+226",
        Burundi: "+257",
        Cambodia: "+855",
        Cameroon: "+237",
        Canada: "+1",
        "Cape Verde": "+238",
        "Central African Rep": "+236",
        Chad: "+235",
        Chile: "+56",
        China: "+86",
        Colombia: "+57",
        Comoros: "+269",
        Congo: "+242",
        "Congo {Democratic Rep}": "+243",
        "Costa Rica": "+506",
        Croatia: "+385",
        Cuba: "+53",
        Cyprus: "+357",
        "Czech Republic": "+420",
        Denmark: "+45",
        Djibouti: "+253",
        Dominica: "+1 767",
        "Dominican Republic": "+1 809",
        "East Timor": "+670",
        Ecuador: "+593",
        Egypt: "+20",
        "El Salvador": "+503",
        "Equatorial Guinea": "+240",
        Eritrea: "+291",
        Estonia: "+372",
        Ethiopia: "+251",
        Fiji: "+679",
        Finland: "+358",
        France: "+33",
        Gabon: "+241",
        Gambia: "+220",
        Georgia: "+995",
        Germany: "+49",
        Ghana: "+233",
        Greece: "+30",
        Grenada: "+1 473",
        Guatemala: "+502",
        Guinea: "+224",
        "Guinea-Bissau": "+245",
        Guyana: "+592",
        Haiti: "+509",
        Honduras: "+504",
        Hungary: "+36",
        Iceland: "+354",
        India: "+91",
        Indonesia: "+62",
        Iran: "+98",
        Iraq: "+964",
        "Ireland {Republic}": "+353",
        Israel: "+972",
        Italy: "+39",
        "Ivory Coast": "+225",
        Jamaica: "+1 876",
        Japan: "+81",
        Jordan: "+962",
        Kazakhstan: "+7",
        Kenya: "+254",
        Kiribati: "+686",
        "Korea North": "+850",
        "Korea South": "+82",
        Kosovo: "+383",
        Kuwait: "+965",
        Kyrgyzstan: "+996",
        Laos: "+856",
        Latvia: "+371",
        Lebanon: "+961",
        Lesotho: "+266",
        Liberia: "+231",
        Libya: "+218",
        Liechtenstein: "+423",
        Lithuania: "+370",
        Luxembourg: "+352",
        Macedonia: "+389",
        Madagascar: "+261",
        Malawi: "+265",
        Malaysia: "+60",
        Maldives: "+960",
        Mali: "+223",
        Malta: "+356",
        "Marshall Islands": "+692",
        Mauritania: "+222",
        Mauritius: "+230",
        Mexico: "+52",
        Micronesia: "+691",
        Moldova: "+373",
        Monaco: "+377",
        Mongolia: "+976",
        Montenegro: "+382",
        Morocco: "+212",
        Mozambique: "+258",
        "Myanmar, {Burma}": "+95",
        Namibia: "+264",
        Nauru: "+674",
        Nepal: "+977",
        Netherlands: "+31",
        "New Zealand": "+64",
        Nicaragua: "+505",
        Niger: "+227",
        Nigeria: "+234",
        Norway: "+47",
        Oman: "+968",
        Pakistan: "+92",
        Palau: "+680",
        Panama: "+507",
        "Papua New Guinea": "+675",
        Paraguay: "+595",
        Peru: "+51",
        Philippines: "+63",
        Poland: "+48",
        Portugal: "+351",
        Qatar: "+974",
        Romania: "+40",
        "Russian Federation": "+7",
        Rwanda: "+250",
        "St Kitts & Nevis": "+1 869",
        "St Lucia": "+1 758",
        "Saint Vincent & the Grenadines": "+1 784",
        Samoa: "+685",
        "San Marino": "+378",
        "Sao Tome & Principe": "+239",
        "Saudi Arabia": "+966",
        Senegal: "+221",
        Serbia: "+381",
        Seychelles: "+248",
        "Sierra Leone": "+232",
        Singapore: "+65",
        Slovakia: "+421",
        Slovenia: "+386",
        "Solomon Islands": "+677",
        Somalia: "+252",
        "South Africa": "+27",
        "South Sudan": "+211",
        Spain: "+34",
        "Sri Lanka": "+94",
        Sudan: "+249",
        Suriname: "+597",
        Swaziland: "+268",
        Sweden: "+46",
        Switzerland: "+41",
        Syria: "+963",
        Taiwan: "+886",
        Tajikistan: "+992",
        Tanzania: "+255",
        Thailand: "+66",
        Togo: "+228",
        Tonga: "+676",
        "Trinidad & Tobago": "+1 868",
        Tunisia: "+216",
        Turkey: "+90",
        Turkmenistan: "+993",
        Tuvalu: "+688",
        Uganda: "+256",
        Ukraine: "+380",
        "United Arab Emirates": "+971",
        "United Kingdom": "+44",
        "United States": "+1",
        Uruguay: "+598",
        Uzbekistan: "+998",
        Vanuatu: "+678",
        "Vatican City": "+379",
        Venezuela: "+58",
        Vietnam: "+84",
        Yemen: "+967",
        Zambia: "+260",
        Zimbabwe: "+263",
      },
      referLoading :false,
      applyCode: '',
      referCode: '', 
      inputValue: '', 
      isVaildRefer:0,


    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => 
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    
  },
  methods: {
    decodeData,
    encodeData,
    formatDateTime,
    navigateToPromotions() {
      this.$router.push({ name: 'Promotions' }); 
      this.profileFunction(false, 'none')
      
      // Assuming 'promotions' is the name of your route
    },
    handleInput() {
      // Set referCode to the current input value when user types
      this.referCode = this.inputValue;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    handleClickOutside(event) {
      if (!this.$refs.searchContainer?.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    async getCatgoryList() {
      try {
        // Retrieve tokens from localStorage
     
        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/game-navigation/get-all-game-navigation?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("somthing went wrong");
        }

        // Parse the response JSON
        const responseData = await response.json();
        // console.log("getcategiryda", responseData);
        this.gameCategory = responseData?.data.filter((ele)=>ele.sidebar_view);

        // Save the retrieved user data
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    openSearchModal() {
      this.showSearchModal = true;
    },
    closeSearchModal() {
      this.showSearchModal = false;
    },

    navigateToPromotion() {
      // Programmatic navigation to the promotion page
      this.$router.push("/promotions");
    },
    getGameProvider(id) {
      // console.log(id, "is gp");
      return providerData.all_providers_name[id] || "Casino";
    },
    nextPage() {
      if (this.currentPage < this.pagination?.totalPages) {
        this.currentPage++;
        // console.log("next", this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        // console.log("previos", this.currentPage);
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    showTooltip(icon) {
      this.tooltips[icon] = true;
    },
    selectGateway(gateway) {
      // console.log(gateway,"cheickn getway")
      this.selectedGateway = gateway;
    },
    async fetchLogoFavicon() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/setting/get-setting/6532c132ed5efb8183a66703?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch favicon data");
        }

        const LogoFeviData = await response.json();
        this.logoFeviconData=LogoFeviData?.data
        // console.log("logo and favicon Data in header:", LogoFeviData);
      } catch (error) {
        console.error("Error fetching favicon data:", error);
      }
    },
    nextStep() {
  let data = JSON.parse(JSON.stringify(this.selectedGateway));

if(!this.inputAmount){
  toast("please fill valid amount")
  // console.log(data,"checking")

  return 
}
if(!this.selectedGateway){
  toast("please select getway")
  // console.log(data,"checking")
  return 
}

 if(this.inputAmount && data){

if(this.inputAmount<=data?.max_limit &&this.inputAmount >= data?.min_limit ){
  this.modalState.step1 = 2;
}
else{
  // console.log(data,"checking else")

  toast( `Please enter an amount between ${data.min_limit} and ${data.max_limit}.`); 
     
}
 }
     
    },
    nextStep1() {
  let data = JSON.parse(JSON.stringify(this.selectedGateway));

if(!this.inputAmount){
  toast("please fill valid amount")
  // console.log(data,"checking")

  return 
}
if(!this.selectedGateway){
  toast("please select getway")
  // console.log(data,"checking")
  return 
}

 if(this.inputAmount && data){

if(this.inputAmount<=data?.max_limit &&this.inputAmount >= data?.min_limit ){
  this.modalState.step2 = 2;
}
else{
  // console.log(data,"checking else")

  toast( `Please enter an amount between ${data.min_limit} and ${data.max_limit}.`); 
     
}
 }
     
    },
    hideTooltip(icon) {
      this.tooltips[icon] = false;
    },

    logout() {
      // Clear local storage
      localStorage.clear();

      // Navigate to home page
      this.$router.push("/");
      toast("User Logout Successfully");
      // Reload the page after 500 milliseconds
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    t(key) {
      return this.$t(key);
    },
    formatDate(inputDate) {
      if (!inputDate) return 'Invalid date';
      
      const now = new Date();
      const date = new Date(inputDate);
      const diffMilliseconds = date - now; // Calculate remaining time

      if (diffMilliseconds < 0) return '00 days 00:00:00'; // If the time has already passed

      const totalSeconds = Math.floor(diffMilliseconds / 1000);
      const days = Math.floor(totalSeconds / (24 * 3600));
      const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      return `${days} days ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(num) {
      return num < 10 ? '0' + num : num;
    },
    filterCurrencyOptions() {
      // Filter currency options based on the selected country
      if (!this.country) {
        this.filteredCurrencyOptions = [];
        return;
      }
      const selectedCountry = this.country;

      const newCurrency = this.currencyOptions.filter((option) => {
        if (option.countriesUsedCurrency.includes(selectedCountry)) {
          return option;
        }
      });
      if (newCurrency.length < 1) {
        this.filteredCurrencyOptions = this.currencyOptions;
      } else {
        this.filteredCurrencyOptions = newCurrency;
      }
      // console.log(newCurrency, "newCurrency");
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    loginFunction(log) {
      if (log) {
        this.login = "block";
        document.body.style.overflow = "hidden";
      } else {
        this.login = "none";
        document.body.style.overflow = "scroll";
        this.showResendEmail = false;
        this.showFillOtpPassword = false;
      }
    },
    validateRegisterEmail() {
      // Regular expression to check email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.Email)) {
        this.emailCheck = true;
        // Display error toast
        // toast('Invalid email format. Please correct it.');
      } else {
        this.emailCheck = false;
      }
    },
    validateUsername() {
      // Regular expression to check username format
      const usernameRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
      if (!usernameRegex.test(this.username)) {
        this.invalidUsername = true;
      } else {
        this.invalidUsername = false;
      }
    },

    validatePassword() {
      // Regular expression to check password format
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(this.password)) {
        this.invalidPassword = true;
      } else {
        this.invalidPassword = false;
      }

      // If both username and password are valid, proceed with submission
    },
    continueClicked() {
      if (this.emailCheck === false && this.Email) {
        this.showOtherDetails = true;
      } else {
        toast("Invalid email format. Please correct it.");
      }
    },
    submitForm() {
      if (this.username == "" || this.password == "") {
        toast("can not field empty");
        return;
      }

      if (this.invalidUsername) {
        toast("Username must be correct");
        return;
      } else if (this.invalidPassword) {
        toast("Password shoulde be correct form");
        return;
      } else if (!this.ageConfirmation) {
        this.formSubmitted = true;
        return;
      } else {
        this.registerLoader = true;

        const payload = {
          username: this.username,
          password: this.password,
          email: this.Email,
          currency: this.currency,
          site_auth_key: this.SITEAUTHKEY,
          refer_by_code:this.referCode||this.applyCode
        };
        // console.log(payload);
        fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/register-user?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return response.json().then((errorData) => {
                throw new Error(errorData?.message || "Failed to login");
              });
            }
            return response.json();
          })
          // .then((response) => {
          //   if (!response.ok) {
          //     throw new Error("Failed to register user");
          //   }
          //   return response.json();
          // })
          .then((data) => {
            this.registerLoader = false;

            // document.querySelector("#staticBackdrop1").click();
            toast("Registration Successful");
            // console.log(data, "login data");
            this.showOtherDetails = false;

            (this.username = ""),
              (this.password = ""),
              (this.Email = ""),
              (this.signup = "none");
            document.body.style.overflow = "scroll";
          })
          .catch((error) => {
            this.registerLoader = false;
            toast(error?.data || error?.message);

            console.error("Error registering user:", error);
          });
      }
    },
    LoginSubmit() {
      if (this.loginUsername == "" || this.loginPassword == "") {
        toast("can not field empty");
        return;
      }
      if (this.loginUsername && this.loginPassword) {
        this.loginMdel1 = false;
        const payload = {
          username: this.loginUsername,
          password: this.loginPassword,
          site_auth_key: this.SITEAUTHKEY,
        };
        // console.log(payload, "payload login");

        this.loginLoader = true;

        fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/user-login?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return response.json().then((errorData) => {
                throw new Error(errorData?.message || "Failed to login");
              });
            }
            return response.json();
          })
          .then((data) => {
            if (data.success) {
              localStorage.setItem("user_authenticated", "true");
              this.isAuthicate = false;
              this.isLogged = true;
              localStorage.setItem("userToken", data.data.token);
              localStorage.setItem("usernameToken", data.data.usernameToken);
              localStorage.setItem(
                "parent_admin_username",
                data.user.parent_admin_username
              );
              localStorage.setItem(
                "user_code",
                data.user.user_code
              );
              localStorage.setItem(
                "parent_admin_role_type",
                data.user.parent_admin_role_type
              );
              this.loginLoader = false;

              localStorage.setItem("User_name", data.user.username);
              //   this.showNotification = true;
                this.fetchUserData();
                this.PaymentMethods();
                this.fetchWidrowMethods();
                toast("Login Successfully", {
                autoClose: 1000,
              });
              this.login = "none";
              setTimeout(() => {
        window.location.reload();
      }, 500);
              document.body.style.overflow = "scroll";
            } else {
              // Handle unsuccessful login
              toast(data?.message);
              // if (data.message === "User not found") {
              //   toast("User not found");
              // } else {
              //   toast("Login Failed", {
              //     autoClose: 1000,
              //   });
              // }
              this.loginLoader = false;
              this.showErrorMessage = true; // Set showErrorMessage only on failed login
            }
          })
          .catch((error) => {
            this.loginLoader = false;

            console.error("Error logging in:", error);
            toast(error?.message, {
              autoClose: 1000,
            });
            this.showErrorMessage = true; // Set showErrorMessage only on failed login
          });
      }
    },

    ForgetPassword() {
      // console.log("forget passw");
      this.showResendEmail = true;
    },
    SendOtp() {
      if (this.registerEmail == "") {
        toast("Enter your register email");
        return;
      } else {
        const payload = {
          email: this.registerEmail,
          site_auth_key: this.SITEAUTHKEY,
        };
        this.sendOtpLoader = true;
        fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/send-reset-otp?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return response.json().then((errorData) => {
                // console.log(errorData, "response errorData");
                toast(
                  errorData?.message === "User not found."
                    ? "Please enter register email"
                    : "somthing went wrong"
                );

                throw new Error();
              });
            }
            return response.json();
          })
          .then((response) => {
            // this.verifyotp="";
            toast(response?.message || "Sent Succesfully");
            this.showFillOtpPassword = true;
            this.showResendEmail = false;

            this.sendOtpLoader = false;
            // Optionally, you can handle the response here
          })
          .catch((error) => {
            console.error("Error sending OTP:", error);
            this.sendOtpLoader = false;

            // Optionally, you can display an error message to the user
          });
      }
    },
    UpdatePassword() {
      const payload = {
        email: this.registerEmail,
        userEnteredOTP: this.otp,
        newPassword: this.newPassword,
        site_auth_key: this.SITEAUTHKEY,
      };

      // Call your API endpoint
      this.verifyOtpLoader = true;
      fetch(
        `${process.env.VUE_APP_API_KEY}/api/user/verify-otp-and-reset?site_auth_key=${this.SITEAUTHKEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to reset password");
          }
          return response.json();
        })
        .then((data) => {
          toast("Your password has been successfully reset");
          this.verifyOtpLoader = false;
          this.registerEmail = "";
          this.otp = "";
          this.newPassword = "";
          this.showResendEmail = false;
          this.showFillOtpPassword = false;
          this.login = "none";
          document.body.style.overflow = "scroll";
        })
        .catch((error) => {
          // Handle errors
          this.verifyOtpLoader = false;

          console.error("Error resetting password:", error);
        });
    },
    CloseLoginOpenSignup() {
      // console.log("closeopen sing");
      this.login = "none";
      document.body.style.overflow = "scroll";
      this.signup = "block";
      document.body.style.overflow = "hidden";
    },
    signUpFunction(log) {
      if (log) {
        this.signup = "block";
        document.body.style.overflow = "hidden";
      } else {
        this.signup = "none";
        document.body.style.overflow = "scroll";
      }
    },
    toggleSidebar() {
      if (window.innerWidth > 1366) {
        this.sidebarWidth = this.sidebarWidth === "0px" ? "240px" : "0px";
        this.displayTopHeaderMobile =
          this.displayTopHeaderMobile === "none" ? "none" : "none";
      } else if (window.innerWidth <= 1366 && window.innerWidth > 478) {
        this.sidebarWidth = this.sidebarWidth === "0px" ? "300px" : "0px";
        this.displayTopHeaderMobile =
          this.displayTopHeaderMobile === "none" ? "flex" : "none";
      } else {
        this.sidebarWidth = this.sidebarWidth === "0px" ? "100%" : "0px";
        this.displayTopHeaderMobile =
          this.displayTopHeaderMobile === "none" ? "flex" : "none";
      }

      this.padding = this.padding === "0px" ? "10px 10px 180px" : "0px";
      this.$refs.navbar__menu_button.classList.toggle("is-open");
    },
    gamesDropdown(index) {
      // Toggle the opened index. If the same index is clicked, close it.
      this.openedIndex = this.openedIndex === index ? null : index;
    },
    profileFunction(opened, type) {
      this.profile = opened ? "block" : "none";
      this.dashboard = type === "dashboard" ? "block" : "none";
      this.history = type === "history" ? "block" : "none";
      this.myrewards = type === "myrewards" ? "block" : "none";
      this.myprofile = type === "myprofile" ? "block" : "none";

      if (type === "dashboard") {
        this.isActive = 0;
      }

      if (type === "history") {
        this.isActive = 1;
      }

      if (type === "myrewards") {
        this.isActive = 2;
      }

      if (type === "myprofile") {
        this.isActive = 3;
      }
    },
    startUserDataFetchingInterval() {
      // Schedule to check and fetch user data every 5 seconds
      this.userDataInterval = setInterval(() => {
        this.fetchUserData();
      }, 5000); // 5000 milliseconds = 5 seconds
    },
    async fetchUserData() {
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Check if tokens exist
        if (!userToken || !usernameToken) {
          console.error("User tokens not found in localStorage");
          return;
        }

        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/get-single-user?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const userData = await response.json();
        // Save the retrieved user data
        this.userData = userData;
        // console.log("User data during in header:", userData);

        localStorage.setItem("username", userData.data.username);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async fetchTransactions() {
      const token = localStorage.getItem("userToken");
      const usernametoken = localStorage.getItem("usernameToken");
      if (!token || !usernametoken) {
          return;
        }

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/transaction/get-all-transaction-by-user?type=all&site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: token,
              usernametoken: usernametoken,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Failed to fetch transactions");
        }
        const data = await response.json();
        console.log(data, "response  transaction data");
        this.transactions = data.data;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    },
    async fetchBetHistory() {
      const token = localStorage.getItem("userToken");
      const usernametoken = localStorage.getItem("usernameToken");
      if (!token || !usernametoken) {
          return;
        }
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/bet/get-all-bet-user?status=all&page=1&limit=20&site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: token,
              usernametoken: usernametoken,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Failed to fetch transactions");
        }
        const data = await response.json();
        // console.log(data, "response data in bet shitpry");
        this.betData = data.data;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    },
    async fetchSecondaryBetHistory() {
      const token = localStorage.getItem("userToken");
      const usernametoken = localStorage.getItem("usernameToken");
      if (!token || !usernametoken) {
          return;
        }
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/bet/get-all-secondary-bet-user?status=all&page=1&limit=20&site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: token,
              usernametoken: usernametoken,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Failed to fetch transactions");
        }
        const data = await response.json();
        console.log(data, "response data in bet secondary shitpry");
        this.secondaryBets = data.data;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    },
    async fetchBonusData() {
      this.isLoading = true;
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Check if tokens exist
        if (!userToken || !usernameToken) {
          // console.error("User tokens not found in localStorage");
          return;
        }

        let url = `${process.env.VUE_APP_API_KEY}/api/bonus-history/get-all-bonus-history-for-user?status=${this.status}&limit=${this.limit}&page=${this.currentPage}&site_auth_key=${this.SITEAUTHKEY}`;

        // Add selected category, sub-category, and status to the URL if they are not empty
        if (this.category) {
          url += `&category=${this.category}`;
        }
        if (this.sub_category) {
          url += `&sub_category=${this.sub_category}`;
        }

        // Make GET request to the API endpoint
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: userToken,
            usernametoken: usernameToken,
          },
        });

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const userBonus = await response.json();
        // console.log("User bonus history details:", userBonus);
console.log(userBonus,"userBonus232323")
        // Save the retrieved user data
        this.userBonus = userBonus.data;
        this.pagination = userBonus?.pagination;
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchBonusData1() {
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Check if tokens exist
        if (!userToken || !usernameToken) {
          // console.error("User tokens not found in localStorage");
          return;
        }

        let url = `${process.env.VUE_APP_API_KEY}/api/bonus-history/get-all-bonus-history-for-user?status=all&site_auth_key=${this.SITEAUTHKEY}`;

        // Add selected category, sub-category, and status to the URL if they are not empty

        // Make GET request to the API endpoint
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: userToken,
            usernametoken: usernameToken,
          },
        });

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const userBonus = await response.json();
        // console.log("User bonus history details:", userBonus);

        // Save the retrieved user data
        this.checkData = userBonus.data;
console.log(checkData,"checkData234234324")

      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
      }
    },
    async PaymentMethods() {
      // console.log("fucntion call");
      try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");
        const parent_admin_role_type = localStorage.getItem(
          "parent_admin_role_type"
        );
        const parent_admin_username = localStorage.getItem(
          "parent_admin_username"
        );

        if (!userToken || !usernameToken) {
      
          return;
        }

        const payload = {
          parent_admin_role_type: localStorage.getItem(
            "parent_admin_role_type"
          ),
          parent_admin_username: localStorage.getItem("parent_admin_username"),
          site_auth_key: this.SITEAUTHKEY,
        };

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/payment/get-payment-method?type=deposit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch payment methods");
        }

        // Parse the response JSON
        const paymentMethods = await response.json();
        // console.log("Payment methods:", paymentMethods);
        // console.log("Payment methods:", paymentMethods.data);
        this.data = paymentMethods.data;

        // Process the payment methods as needed
        // For example, update UI, display options to the user, etc.
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    },
    async fetchPromotionData() {
        // Retrieve tokens from localStorage
       
      this.loading = true;

      try {
      

        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/promotion/get-all-promotion-user?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
                
             
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const responseData = await response.json();
        // console.log("User data123:", responseData.promotions);

        // Save the retrieved user data
        this.Promotions = responseData.promotions; // Corrected variable name
        this.loading = false;
      } catch (error) {
        console.error("Error fetching user data:", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    viewDetails(promotionId) {
      // Use Vue Router to navigate to the details page and pass the promotionId
      this.$router.push({
        name: "PromotionDetails",
        params: { promotionId: promotionId },
      });
    },
    async fetchWidrowMethods() {
      // console.log("function call");
      try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");
        const parent_admin_role_type = localStorage.getItem(
          "parent_admin_role_type"
        );
        const parent_admin_username = localStorage.getItem(
          "parent_admin_username"
        );

        if (!userToken || !usernameToken) {
         
          return;
        }

        const payload = {
          parent_admin_role_type: localStorage.getItem(
            "parent_admin_role_type"
          ),
          parent_admin_username: localStorage.getItem("parent_admin_username"),
          site_auth_key: this.SITEAUTHKEY,
        };

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/payment/get-payment-method?type=withdraw`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch widrow methods");
        }

        // Parse the response JSON
        const paymentMethods = await response.json();
        // console.log("widrow methods:", paymentMethods);
        // console.log("widrow methods:", paymentMethods.data);
        this.widrowData = paymentMethods.data;

        // Process the payment methods as needed
        // For example, update UI, display options to the user, etc.
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    },
    async handleFileChange(event, index) {
      // console.log("func");
      const file = event.target.files[0];
      this.selectedFiles[index] = file;
      // console.log(this.selectedFiles);
      this.userInputs[index] = file;

      const formData = new FormData();
      formData.append("post_img", file);

      try {
        this.imgLoader = true; // Show loader when starting image upload

        // console.log(this.imgLoader);
        const response = await this.sendPostRequest(
          `${process.env.VUE_APP_API_KEY}/api/payment/image-url?site_auth_key=${this.SITEAUTHKEY}`,
          formData
        );

        if (response.url) {
          this.imgLoader = false; // Hide loader when image upload is successful
          // console.log(response.url, "response url");
          this.Image_url = response.url;
          // console.log(this.imgLoader);
        }

        return response.url;
      } catch (error) {
        this.imgLoader = false; // Hide loader when image upload fails
        // console.log(this.imgLoader);
        console.error("Error uploading image:", error.message);
      }
    },
    async sendPostRequest(url, formData) {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json(); // Assuming your API returns JSON response
        return responseData;
      } catch (error) {
        throw new Error(error.message || "Error sending POST request");
      }
    },
    async nextWidrow1() {
      let data = JSON.parse(JSON.stringify(this.selectedGateway));
     this.WithDrawLoader=true
      try {
        // Retrieve user tokens and ID
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Extract necessary user data from userData
        const userId = this.userData.data.x;
        const username = this.userData.data.username;

        if (!userToken || !usernameToken) {
              this.WithDrawLoader=false
          return;
        }

        // Create an empty array to store user details
        const userDetails = [];

        // Loop through userInputs array to get user input values
        this.userInputs.forEach((inputValue, index) => {
          // Get the corresponding user detail from promotions array
          const userDetail = data.user_details[index];
          // Create an object with name, value, and required properties
          let detailValue;
          if (userDetail.type === "file") {
            // For file inputs, use the selected file from selectedFiles array
            // console.log(this.selectedFiles[index].File);
            // console.log(this.selectedFiles[index].name);
            detailValue = this.selectedFiles[index].name;
            // Construct the file detail object to include in userDetails
            const fileDetailObject = {
              name: userDetail.name,
              value: detailValue,
              required: userDetail.required === "true",
            };
          } else {
            // Handle other input types here if needed
            detailValue = inputValue; // Default value for unsupported types
          }
          const detailObject = {
            name: userDetail.name,
            value: detailValue,
            required: userDetail.required === "true",
          };
          // Push the detailObject to userDetails array
          userDetails.push(detailObject);
        });

        let countRequred=data?.user_details?.filter(detail => detail.required).length;
if(countRequred!==userDetails.length){
  // console.log(countRequred,userDetails.length,"cehcking requird count")
  return toast("fill all user bank required details")
}
        const ids = localStorage.getItem("User_name");
        const payload = {
          method: data.gateway,
          method_id: data._id,
          method_url: data.image,
          username: username,
          user_id: userId,
          withdraw_amount: this.inputAmount,
          bonus: data.bonus,
          withdraw_slip: "",
          payable: Number(this.inputAmount),
          after_withdraw:
            Number(this.userData.data.amount) - Number(this.inputAmount),
          wallet_amount: this.userData?.data?.amount,
          admin_response: "",
          user_details: userDetails,
          admin_details: data.admin_details,
          utr_no: "",
          type: data.type,
          user_type: "user",
          currency: this.userData?.data?.currency,
          site_auth_key:this.SITEAUTHKEY

        };

        // Make the POST request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/transaction/create-withdraw-request/${ids}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );
        // console.log("response hg:", response);
        this.WithDrawLoader=false

        // Check if the request was successful
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData?.message);
        }

        // Parse the response JSON
        // console.log("withdraw request created successfully:", responseData);
        toast("withdraw request created successfully");
        this.WithDrawLoader=false
        this.closeModal2()

      } catch (error) {
this.WithDrawLoader=false

        console.error(error);
        toast(error?.message || error?.data?.message);
      }
    },
    CreateWithDraw() {
      // console.log("withdraw");
      this.nextWidrow1()
    },
    async handleApplyReferCode() {
      // console.log("fucntion call");
      this.referLoading=true

      try {
       
        const payload = {
          refer_by_code:this.referCode||this.applyCode,
        };

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/validate-refer-code?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

     
        const data = await response.json();
       
  console.log(data,"data")
this.isVaildRefer=1
  toast(data?.message)

      } catch (error) {
        console.error("Error appling refer code", error);
  toast(error?.message)
  this.isVaildRefer=2


      }
      finally{
      this.referLoading=false

      }
    },
   
  },
  created() {
    // Use localStorage.getItem to retrieve data from local storage
    const userAuthenticated = localStorage.getItem("user_authenticated");
    this.isLogged = userAuthenticated === "true";

    // Check if the user is authenticated, and set showButton accordingly
    if (this.isLogged) {
      this.isAuthicate = false;
    } else {
      this.isAuthicate = true;
    }
  },
  watch: {
    category(newCategory, oldCategory) {
      if (newCategory !== oldCategory) {
        this.fetchData();
      }
    },
    sub_category(newCategory, oldCategory) {
      if (newCategory !== oldCategory) {
        this.fetchData();
      }
    },
    status(newValue, oldValue) {
      // console.log("Selected Status changed from", oldValue, "to", newValue);
    },
    currentPage: {
      handler: "fetchBonusData",
      immediate: true,
    },

    limit: {
      handler: "fetchBonusData", // Call fetchUserData when limit changes
      immediate: true,
    },
    category: {
      handler: "fetchBonusData", // Call fetchUserData when category changes
      immediate: true,
    },
    sub_category: {
      handler: "fetchBonusData", // Call fetchUserData when sub_category changes
      immediate: true,
    },
    status: {
      handler: "fetchBonusData", // Call fetchUserData when status changes
      immediate: true,
    },
  },
  mounted() {
    const siteAuthKey = process.env.VUE_APP_API_SITE_AUTH_KEY;
    // console.log(siteAuthKey, "checking site Auth key");
    this.SITEAUTHKEY = siteAuthKey;
    const preferredLanguage = localStorage.getItem("storeLanguage");
    this.selectLangauge = preferredLanguage;
    const userToken = localStorage.getItem("userToken");
    const usernameToken = localStorage.getItem("usernameToken");
    document.addEventListener('click', this.handleClickOutside);

    this.fetchUserData();
    this.fetchTransactions();
    this.getCatgoryList()
    this.fetchBonusData1();
    this.fetchPromotionData();
    this.fetchBonusData();
    this.fetchBetHistory();
    this.fetchLogoFavicon()
    // this.fetchSecondaryBetHistory()
    // console.log("ram");
    // console.log(userToken, "usertoken", usernameToken);

    if (userToken && usernameToken) {
      // console.log(this.userToken, "usertoken", this.usernameTokem);
      this.startUserDataFetchingInterval();
    }
     // Use URLSearchParams to retrieve the query parameter from the current URL
     const queryParams = new URLSearchParams(window.location.search);

// Make sure you're using the exact key from the query string (e.g., 'refer_code')
this.applyCode = queryParams.get('refer_code');
this.inputValue = this.applyCode;
// If the referCode exists, open the registration modal
if (this.applyCode && (!userToken&&!usernameToken)) {
  // this.$refs.registerModal.openModal();
  this.signUpFunction(true)
console.log(this.applyCode, 'Retrieved refer_code  inside'); // For debugging

}
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.userDataInterval);
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>

.referral-container{
  margin-top:-50px;
  margin-bottom:30px;
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
align-items:flex-end
}
.apply-btn{
position:absolute;
top:0px;
right:0px;
width:60px;
height:35px;
font-size:12px;
display:flex;
align-items:center;
justify-content:center
}
.refer-input{
font-size:12px;
padding:10px;
}

.spinnerApply{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}


@media (max-width: 854px) {
.signuphide{
display:none
}

}


@media (min-width: 854px) {
.signupMobile{
display:none
}

}

@media (min-width: 500px) {
.hide-desktop{
display:none
}

}

.drop-arrow{
  width:100px; 
  height:100px;
}

.dropdown-icon {
  width: 24px;
  height: 24px;
  color: white; /* You can change the color as needed */
  cursor: pointer;
  margin-top:-40px;
}
.icon-container {
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.white-icon {
  color: white; /* Set icon color to white */
}

.tooltip {
  display: none;
  position: absolute;
  top: 90%;
  left: -200%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #3b3955;
  color: white;
  margin-top: 5px;
  border-radius: 10px;
  width: 260px;
  height: 250px;
}
.logout-css:hover {
  background-color: #1b1928;
}
.depsoitModal {
z-index:100;
    padding:15px;
}
.logout-css {
  border-radius: 10px;
  padding: 10px;
}

.tooltipS {
  display: none;
  position: absolute;
  top: 90%;
  left: -320%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #3b3955;
  color: white;
  margin-top: 5px;
  border-radius: 10px;
  width: 350px;
  height: 240px;
  z-index: 10;
}


.icon-container:hover .tooltip {
  display: block;
}

.blur-background {
  filter: blur(5px); /* Apply blur effect */
  pointer-events: none; /* Disable interactions with blurred content */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(61, 58, 58, 0.5);
}


.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  width: 34%;
  background-color: white;
  border-radius: 16px;
  padding: 10px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: start;
  align-items: start;
}
.large-text {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
}
.input-field {
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
  font-size: 20px;
  width: 100%;
  padding: 8px;
}
.payment-cards {
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.card {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100px;
  transition: background-color 0.3s;
}
.card.selected {
  background-color: #3861fb;
  color: white;
}
.card:hover {
  background-color: #f0f0f0;
}
.depsoitBtn {
  padding: 10px 20px;
  background-color: #01bd71;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.depsoitBtn:disabled {
  background-color: #01bd71;
  cursor: not-allowed;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  height: 500px;
}
.bets {
  width: 100%;
  padding: 15px;
}
.transaction-row {
  display: flex;
  border-bottom: 1px solid #ccc;
  /* background: #404040; */
  width: 100%;
  text-align: left;
}

.search-cross{
  width:20px;
  height:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:4px;
  background-color:#56546E;
  color:white;
  font-size:14px;
  font-weight:bold
}

.transaction-row.header {
  font-weight: bold;
}
.transaction-cell {
  flex: 1;
  padding: 10px;
}
.text-center {
  text-align: center;
}
.balanceHistory {
  font-size: 20px;
  width: 50%;
  line-height: 120%;
  font-weight: 900;
  font-style: italic;
  color: rgb(var(--whiteColor));
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
  color: #bbf7d0;
  font-size: 40px;
  text-align: center;
}



.spinnerPromotion {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}


.c-search-content {
  position: relative;
  display: flex;
  align-items: center;
}
.c-search-content-active{
  background-color:#3B3955

}


.c-search-input {
  width: 100%;

}
.c-search-input-active{
  background-color:#3B3955

}

.c-dropdown {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background-color:#3B3955;
  z-index: 105;
  border-radius:8px;
  width:700px;
  
  min-height:300px
}

.c-dropdown-arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  /* border-color: transparent transparent white transparent; */
}

.c-dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.c-dropdown-item:hover {
  background: #f0f0f0;
}


@media (min-width: 1024px) {

    .mobileSidebar{
    display:none
}
.header-part1{
  width:100%
}

}

@media (max-width: 1024px) {
  .c-dropdown {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  /* background-color:#3B3955; */
  border-radius:0px;
  width:100%;
  background-color: #2B2740;
}
}
@media (max-width: 1400px) {

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  width: 40%;
  background-color: white;
  border-radius: 16px;
  padding: 10px;
  position: relative;
}
}

@media (max-width: 1024px) {
  .icon-container {
    display: none;
  }


  .sidebarhide{
    display:none;
}
.mobileSidebar{
    display:content;
    margin-top:10px;
    margin-bottom:10px;

}
.c-search-content{
  display:none
}

}
@media (max-width: 854px) {
  .icon-container {
    display: none;
  }
  .sidebarhide{
    display:none;
}
.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 0px;
  top:0px;
  text-align: center;
  width: 100%;
  height:100%;
  background-color: white;
  padding: 10px;
  position: fixed;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: black
}
.promotion-mbox{
  padding:20px;
  margin-top:-30px
}

}
@media (max-width: 500px){
.amount-hide{
  display:none
}
.once-more{
padding:0px;
padding-left:10px;
padding-right:10px

}
}


</style>
